import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";

import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';
import {ReactComponent as SendIcon} from './icons/send_fill1_24px.svg';
import {ReactComponent as LanguageIcon} from './icons/language_fill1_20px.svg';
import {useNavigate} from "react-router-dom";


const ContainerL1 = styled.div`
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    flex-direction: row;  // Change this from column to row
    flex-wrap: wrap;
    align-items: baseline;
`;

const StoryPrompt = styled.span`
    font-size: 12px;
    padding-bottom: 5px;
    font-family: Space Grotesk,sans-serif;
    margin-right: 10px;
`;

const StoryTextArea = styled.textarea`
    position: absolute;
    bottom: 10px;
    flex-grow: 1;
    padding: 7px;
    border-radius: 10px;
    width: 80%;
    height: 45px;
    border: #f2efff;

    @media (max-width: 800px) {
        width: 95%;
    }
`;

const Select = styled.select`
    right: 4px;  
    font-size: 14px;
    padding: 4px;
    border-radius: 10px;  // rounded border
    border: 1px solid #000000; // purple border

    @media (max-width: 800px) {
        font-size: 10px;
    }
`;

interface FooterContainerProps {
    disabled?: boolean; // Propriété disabled optionnelle de type boolean
}
const FooterContainer = styled.div<FooterContainerProps>`
    background-color: #f2efff; 
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    position: fixed;
    bottom: 0px;
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 6px;
    height: 90px;
`;

const LANGUAGE_OPTIONS = [
    'english',
    'spanish',
    'arabic',
    'french',
    'german',
    'chinese',
    'japanese',
    'hindi',
    'turkish',
    'korean',
    'italian',
    'dutch',
    'russian',
    'indonesian',
] as const;
type LanguageOption = typeof LANGUAGE_OPTIONS[number];

const languageCodeToName: Record<string, LanguageOption> = {
    'en': 'english',
    'es': 'spanish',
    'ar': 'arabic',
    'fr': 'french',
    'de': 'german',
    'zh': 'chinese',
    'ja': 'japanese',
    'hi': 'hindi',
    'tr': 'turkish',
    'ko': 'korean',
    'it': 'italian',
    'nl': 'dutch',
    'ru': 'russian',
    'id': 'indonesian',
};
const Home = () => {
    const [text, setText] = useState<string>("");


    const { t } = useTranslation();

    const initialLanguage = 'en';
    const [language, setLanguage] = useState<LanguageOption>(languageCodeToName[initialLanguage]);

    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        Prism.highlightAll();
        if (token) {
            navigate('/conversation');
        }
    }, []);

    return (
        <div>
            <FooterContainer disabled={true}>
                <ContainerL1>
                    <StoryPrompt>{t('conversationIn')}</StoryPrompt>
                    <Select value={language}>
                        <option value=""><LanguageIcon/></option>
                        {LANGUAGE_OPTIONS.map((lang) => (
                            <option key={lang} value={lang}>
                                {t(lang)}
                            </option>
                        ))}
                    </Select>
                </ContainerL1>
                <StoryTextArea value={text} onChange={e => setText(e.target.value)}>
                </StoryTextArea>
                <SendIcon style={{position: 'absolute', right: '3px', bottom: '10px'}} >{t('send')}</SendIcon>

            </FooterContainer>
        </div>
    );
};

export default Home;
