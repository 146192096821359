import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const resources = {
    en: {
        translation: {
            "playAll": "Play All",
            "conversationIn": "Conversation in",
            "between": "between",
            "and":  "and",
            "subject": "Subject",
            "conversation": "Conversation",
            "copy": "COPY",
            "assistance": "Assist",
            "answers": "Answers",
            "explanations": "Analyse and Explain",
            "summary": "Summary",
            "translation": "Translation",
            "other": "Other request",
            "other_example": "Example: Analyze this document",
            "audio": "Audio",
            "mediaSource": "To request {{kind}}",
            "scan": "Scan a document",
            "upload": "Upload a document",
            "text": "Enter text",
            "kind": "{{kind}} in progress...",
            "responseLang": " in ",
            "question": "Enter your question",
            "send": "Send",
            "chooseSourceType": "I'm looking for",
            "comingSoon": "Coming soon",
            "homeTitle": "Simplify Your Life with the Power of ChatGPT 4",
            "homeText": "With ChatGPT 4 and other AIs, revolutionize your experience with our innovative app! <br/>" +
                "Scan, upload, and explore documents in the blink of an eye.<br/>" +
                "Chat in text or voice, create dialogues, and learn new languages with ease.<br/>" +
                "Join us now! 🚀<br/>",
            "generateConversation": "Generate Conversation",
            "voiceConversation": "Voice ChatGPT 4",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Specific Question",
            "conversations": "My Conversations",
            "uploads": "My Uploads/Scans",
            "clear": "Clear",
            "errorInternetConnection": "Error: No internet connection, please try again later.",
            "result": "Result",
            "resultDocument": "Get answers to the document's questions.",
            "understandDocument": "Understand the content of the document.",
            "summaryDocument": "Get a summary of the document.",
            "downloadPdf": "Download PDF",

            "unsatisfactoryResultMessage": "If the results are unsatisfactory, click on ",
            "imageUploadSuccess": "Image uploaded successfully!",
            "downloadForEdition": "Download<br/>for editing",
            "resendImage": "Resend<br/>the image",
            "delete": "Delete",
            "retry": "Retry",
            "imageLoading": "Image loading",
            "howItWork": "How it works",
            "retryIndication": "If your document includes exercises or academic exams, AI cannot process them directly. To address this, we have converted your document into images, which you can download and edit as needed.<br/>" +
                "<br/>" +
                "Elements to remove from the document include:<br/><dl>" +
                "<dd>- School logo and name</dd>" +
                "<dd>- Document title</dd>" +
                "<dd>- Scoring or grading for each question</dd>" +
                "<dd>- Any information indicating the document's origin</dd></dl><br/>" +
                "How to proceed:" +
                "<ol>" +
                "<li>Download for editing: Allows you to download the image to your device. Use an image editing application, such as Paint on Windows.</li>" +
                "<li>Resend edited image: Allows you to upload the image you just modified.</li>" +
                "<li>Deletion: In some cases, you may need to delete the image if it does not contain any exercises.</li>" +
                "<li>Once the images are edited, click the Retry button.</li></ol>",

            "history": "History",
            "originalDocument": "Original document",
            "generatedImagesList": "List of generated images",
            "page": "Page",
            "requestDate": "Request Date",
            "usedPagesList": "List of used pages",
            "response": "Response",
            "noDownloadsAvailable": "No downloads available",

            "videoId1": "ewOZYPrtDPU",
            "videoId2": "60Zx9RPsx4Y",
            "videoLabel1": "How does it work?",
            "videoLabel2": "Exploration and Tips",

            "story": "a story",
            "riddle": "a riddle",
            "joke": "a joke",

            "tellStory": "Tell me {{kind}} about",
            "sayAndClick": "Say what you want then click here",
            "takePhotoAndClick": "Take a photo of your drawing and click here",
            "start": "Click here to start",
            "wildAnimals": "Example: The wild animals",
            "home": "Home",
            "myChannel": "My Channel",
            "subscriptions": "Subscriptions",
            "hideSolution": "Hide Solution",
            "showSolution": "Show Solution",
            "watchStories": "I dive into my films",
            "listenToStories": "I discover my wonderful tales",
            "funChallenges": "I meet my fantastic riddles",
            "exploreMyJokes": "I explore my universe of jokes",

            "listenAnotherStory": "listen to another story",
            "watchAnotherStory": "watch another story",
            "playARiddle": "play a riddle",
            "giggleWithAnotherJoke": "Giggle with another joke",
            "pending": "pending",
            "myAccount": "My account",
            "signOut": "Sign out",
            "signInWithGoogle": "Sign in",
            "featureNotYetAvailable": "Feature not yet available",
            "title": "Title:",
            "yearsOld": "years old",
            "im": "I'm",
            "show": "Show",
            "hide": "Hide",
            "showStory": "Show Story Text",
            "hideStory": "Hide Story Text",
            "surpriseMe": "Surprise me",
            "english":"English",
            "spanish":"Spanish",
            "arabic":"Arabic",
            "french":"French",
            "german":"German",
            "chinese":"Chinese",
            "japanese":"Japanese",
            "hindi":"Hindi",
            "turkish":"Turkish",
            "korean":"Korean",
            "italian":"Italian",
            "dutch":"Dutch",
            "russian":"Russian",
            "indonesian":"Indonesian",
            "alphabet": "Alphabet",
            "numbers": "Numbers",
            "learning": "Learning",
            "languages": "Languages",
            "selectLanguage": "Select a language",
            "selectLearningType": "Select a learning type",
            "words": "Words",
            "selectType": "Select a type",
            "animals": "Animals",
            "colors": "Colors",
            "fruits": "Fruits",
            "vegetables": "Vegetables",
            "foods": "Foods",
            "verbs": "Verbs",
            "houses": "House",
            "schools": "School",
            "supplies": "School supplies",
            "natures": "Nature",
            "places": "places & transport",
            "occupations": "Occupations",
            "iWantToLearn" : "I want to learn"
        }
    },
    es: {
        translation: {
            "playAll": "Reproducir todo",
            "conversationIn": "Diálogo en",
            "between": "entre",
            "and": "y",
            "subject": "Asunto",
            "conversation": "Diálogo",
            "copy": "COPIAR",
            "assistance": "Asistencia",
            "answers": "Respuestas",
            "explanations": "Analizar y Explicar",
            "summary": "Resumen",
            "translation": "La traducción",
            "other": "Otra solicitud",
            "other_example": "Ejemplo: Analiza este documento",
            "audio": "Audio",
            "mediaSource": "Para solicitar {{kind}}",
            "scan": "Escanear un documento",
            "upload": "Subir un documento",
            "text": "Ingresar texto",
            "kind": "{{kind}} en progreso...",
            "responseLang": " en ",
            "question": "Ingresar tu pregunta",
            "send": "Enviar",
            "chooseSourceType": "Estoy buscando",
            "comingSoon": "Próximamente disponible",
            "homeTitle": "Simplifica tu vida con el poder de ChatGPT 4",
            "homeText": "¡Con ChatGPT 4 y otras IA, revoluciona tu experiencia con nuestra innovadora aplicación! <br/>" +
                "Escanea, sube y explora documentos en un abrir y cerrar de ojos.<br/>" +
                "Chatea en texto o voz, crea diálogos y aprende nuevos idiomas con facilidad.<br/>" +
                "¡Únete a nosotros ahora! 🚀<br/>",
            "generateConversation": "Generar Conversación",
            "voiceConversation": "ChatGPT 4 de Voz",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Pregunta Específica",
            "conversations": "Mis Diálogos",
            "uploads": "Mis Subidas/Escaneos",
            "clear": "Limpiar",
            "errorInternetConnection": "Error: Sin conexión a internet, por favor inténtalo de nuevo más tarde.",
            "result": "Resultado",
            "resultDocument": "Obtener respuestas a las preguntas del documento.",
            "understandDocument": "Comprender el contenido del documento.",
            "summaryDocument": "Obtener un resumen del documento.",
            "downloadPdf": "Descargar PDF",
            "unsatisfactoryResultMessage": "Si los resultados no son satisfactorios, haz clic en ",
            "imageUploadSuccess": "¡La imagen se ha cargado con éxito!",
            "downloadForEdition": "Descargar<br/>para editar",
            "resendImage": "Reenviar<br/>la imagen",
            "delete": "Eliminar",
            "retry": "Reintentar",
            "imageLoading": "Cargando imagen",
            "howItWork": "Cómo funciona",
            "retryIndication": "Si su documento incluye ejercicios o exámenes académicos, la IA no puede procesarlos directamente. Para solucionar esto, hemos convertido su documento en imágenes, que puede descargar y editar según sea necesario.<br/>" +
                "<br/>" +
                "Elementos a eliminar del documento incluyen:<br/><dl>" +
                "<dd>- Logotipo y nombre de la institución educativa</dd>" +
                "<dd>- Título del documento</dd>" +
                "<dd>- Puntuación o calificación de cada pregunta</dd>" +
                "<dd>- Cualquier información que indique el origen del documento</dd></dl><br/>" +
                "Cómo proceder:" +
                "<ol>" +
                "<li>Descargar para editar: Le permite descargar la imagen en su dispositivo. Utilice una aplicación de edición de imágenes, como Paint en Windows.</li>" +
                "<li>Reenviar la imagen editada: Le permite cargar la imagen que acaba de modificar.</li>" +
                "<li>Eliminación: En algunos casos, es posible que deba eliminar la imagen si no contiene ningún ejercicio.</li>" +
                "<li>Una vez que las imágenes estén editadas, haga clic en el botón Reintentar.</li></ol>",

            "history": "Historial",
            "originalDocument": "Documento original",
            "generatedImagesList": "Lista de imágenes generadas",
            "page": "Página",
            "requestDate": "Fecha de solicitud",
            "usedPagesList": "Lista de páginas utilizadas",
            "response": "Respuesta",
            "noDownloadsAvailable": "No hay descargas disponibles",

            "sayAndClick": "Di lo que quieras y luego haz clic aquí",
            "takePhotoAndClick": "Toma una foto de tu dibujo y haz clic aquí",
            "start": "Haz clic aquí para comenzar",
            "wildAnimals": "Ejemplo: Los animales salvajes",
            "home": "Inicio",
            "myChannel": "Mi canal",
            "subscriptions": "Suscripciones",
            "hideSolution": "Ocultar la solución",
            "showSolution": "Mostrar la solución",
            "makeVideoStory": "Creo mi película mágica",
            "makeAudioStory": "Diseño mi cuento sonoro",
            "startFunChallenge": "Resuelvo acertijos misteriosos",
            "craftMyJokes": "Creo mi propio universo de bromas",
            "watchStories": "Me sumerjo en mis películas",
            "listenToStories": "Descubro mis cuentos maravillosos",
            "funChallenges": "Encaro mis desafíos fantásticos",
            "exploreMyJokes": "Exploro mi universo de bromas",
            "storyInPreparation": "Historia en preparación... ¿Por qué no",
            "listenAnotherStory": "escuchar otra historia",
            "watchAnotherStory": "ver otra historia",
            "playARiddle": "jugar un acertijo",
            "giggleWithAnotherJoke": "Ríe con otra broma",
            "pending": "pendiente",
            "myAccount": "Mi cuenta",
            "signOut": "Cerrar sesión",
            "signInWithGoogle": "Iniciar sesión",
            "featureNotYetAvailable": "Función no disponible aún",
            "title": "Título:",
            "yearsOld": "años",
            "im": "Tengo",
            "show": "Mostrar",
            "hide": "Ocultar",
            "showStory": "Mostrar texto de la historia",
            "hideStory": "Ocultar texto de la historia",
            "surpriseMe": "Sorpréndeme",
            "english":"Inglés",
            "spanish":"Español",
            "arabic":"Árabe",
            "french":"Francés",
            "german":"Alemán",
            "chinese":"Chino",
            "japanese":"Japonés",
            "hindi":"Hindi",
            "turkish":"Turco",
            "korean":"Coreano",
            "italian":"Italiano",
            "dutch":"Holandés",
            "russian":"Ruso",
            "indonesian":"Indonesio",
            "alphabet": "Alfabeto",
            "numbers": "Números",
            "learning": "Aprendiendo",
            "languages": "Idiomas",
            "selectLanguage": "Selecciona un idioma",
            "selectLearningType": "Seleccione el tipo de aprendizaje",
            "words": "Palabras",
            "selectType": "Selecciona un tipo",
            "animals": "Animales",
            "colors": "Colores",
            "fruits": "Frutas",
            "vegetables": "Verduras",
            "foods": "Comidas",
            "verbs": "Verbos",
            "houses": "Casa",
            "schools": "Escuela",
            "supplies": "Útiles escolares",
            "natures": "Naturaleza",
            "places": "Lugares y Transporte",
            "occupations": "Ocupaciones",
            "iWantToLearn" : "Quiero aprender"
        }
    },
    ar: {
        translation: {
            "playAll": "تشغيل الكل",
            "conversationIn": "الحوار ب",
            "between": "بين",
            "and": "و",
            "subject": "الموضوع",
            "conversation": "حوار",
            "copy": "نسخ",
            "assistance": "مساعدة",
            "answers": "إجابات",
            "explanations": "تحليل و شرح",
            "summary": "ملخص",
            "translation": "الترجمة",
            "other": "طلب آخر",
            "other_example": "مثال: قم بتحليل هذا المستند",
            "audio": "صوت",
            "mediaSource": "لطلب {{kind}}",
            "scan": "مسح وثيقة",
            "upload": "تحميل وثيقة",
            "text": "إدخال نص",
            "kind": "{{kind}} قيد التحضير...",
            "responseLang": " في ",
            "question": "أدخل سؤالك",
            "send": "إرسال",
            "chooseSourceType": "أبحث عن",
            "comingSoon": "قريباً",
            "homeTitle": "بسّط حياتك مع قوة تشات جي بي تي ChatGPT 4",
            "homeText": "مع تشات جي بي تي ٤ والذكاء الاصطناعي الآخر، قم بثورة في تجربتك مع تطبيقنا المبتكر! <br/>" +
                "امسح، ارفع، واستكشف الوثائق في لمح البصر.<br/>" +
                "تحدث بالنص أو بالصوت، أنشئ حوارات، وتعلم لغات جديدة بسهولة.<br/>" +
                "انضم إلينا الآن! 🚀<br/>",
            "generateConversation": "إنشاء حوار",
            "voiceConversation": "محادثة صوتية",
            "textConversation": "محادثة نصية",
            "specificQuestion": "سؤال محدد",
            "conversations": "حواراتي",
            "uploads": "تحميلاتي/مسحاتي",
            "clear": "امسح",
            "errorInternetConnection": "لا يوجد اتصال بالإنترنت، يرجى المحاولة مرة أخرى لاحقًا.",
            "result": "نتيجة",
            "resultDocument": "الحصول على إجابات على أسئلة الوثيقة.",
            "understandDocument": "فهم محتوى الوثيقة.",
            "summaryDocument": "الحصول على ملخص للوثيقة.",
            "downloadPdf": "تحميل PDF",
            "unsatisfactoryResultMessage": "إذا كانت النتائج غير مرضية، انقر على ",
            "imageUploadSuccess": "تم تحميل الصورة بنجاح!",
            "downloadForEdition": "تحميل<br/>للتعديل",
            "resendImage": "إعادة<br/>إرسال الصورة",
            "delete": "حذف",
            "retry": "إعادة المحاولة",
            "imageLoading": "جاري تحميل الصورة",
            "howItWork": "كيف يعمل",
            "retryIndication": "إذا كان مستندك يحتوي على تمارين أو امتحانات أكاديمية، فإن الذكاء الاصطناعي لا يستطيع معالجتها مباشرة. للتعامل مع هذا، قمنا بتحويل مستندك إلى صور، يمكنك تحميلها وتعديلها حسب الحاجة.<br/>" +
                "<br/>" +
                "العناصر التي يجب إزالتها من المستند تشمل:<br/><dl>" +
                "<dd>- شعار المدرسة واسمها</dd>" +
                "<dd>- عنوان المستند</dd>" +
                "<dd>- التنقيط أو التقدير لكل سؤال</dd>" +
                "<dd>- أي معلومات تشير إلى أصل المستند</dd></dl><br/>" +
                "كيفية المتابعة:" +
                "<ol>" +
                "<li>تحميل للتعديل: يتيح لك تحميل الصورة على جهازك. استخدم تطبيق تحرير الصور مثل Paint على نظام التشغيل Windows.</li>" +
                "<li>إعادة إرسال الصورة المعدلة: يتيح لك تحميل الصورة التي قمت للتو بتعديلها.</li>" +
                "<li>الحذف: في بعض الحالات، قد تحتاج إلى حذف الصورة إذا لم تحتوي على أي تمارين.</li>" +
                "<li>بمجرد تعديل الصور، انقر على زر إعادة المحاولة.</li></ol>",

            "history": "سجل",
            "originalDocument": "الوثيقة الأصلية",
            "generatedImagesList": "قائمة الصور المولدة",
            "page": "صفحة",
            "requestDate": "تاريخ الطلب",
            "usedPagesList": "قائمة الصفحات المستخدمة",
            "response": "الرد",
            "noDownloadsAvailable": "لا توجد تنزيلات متاحة",

            "start": "اضغط هنا للبدء",
            "wildAnimals": "مثال: الحيوانات البرية",
            "home": "الرئيسية",
            "myChannel": "قناتي",
            "subscriptions": "الاشتراكات",
            "hideSolution": "إخفاء الحل",
            "showSolution": "عرض الحل",
            "makeVideoStory": "أنشئ فيلمي السحري",
            "makeAudioStory": "أصمم حكايتي الصوتية",
            "startFunChallenge": "أحل الألغاز الغامضة",
            "craftMyJokes": "أنشئ عالمي الخاص من النكات",
            "watchStories": "أغوص في أفلامي",
            "listenToStories": "أكتشف قصصي الرائعة",
            "funChallenges": "أواجه تحدياتي الرائعة",
            "exploreMyJokes": "أستكشف كوني من النكت",
            "storyInPreparation": "القصة قيد التحضير... لماذا لا",
            "listenAnotherStory": "استمع لقصة أخرى",
            "watchAnotherStory": "شاهد قصة أخرى",
            "playARiddle": "لعب لغز",
            "giggleWithAnotherJoke": "اضحك مع نكتة أخرى",
            "pending": "قيد الانتظار",
            "myAccount": "حسابي",
            "signOut": "تسجيل الخروج",
            "signInWithGoogle": "تسجيل الدخول",
            "featureNotYetAvailable": "الميزة غير متاحة بعد",
            "title": "العنوان:",
            "yearsOld": "سنوات",
            "im": "عندي",
            "show": "أظهر",
            "hide": "إخفاء",
            "showStory": "أظهر نص القصة",
            "hideStory": "أخف نص القصة",
            "surpriseMe": "فاجئني",
            "chooseForMe": "",
            "english": "إنجليزية",
            "spanish": "إسبانية",
            "arabic": "عربى",
            "french": "فرنسي",
            "german": "ألمانية",
            "chinese": "صينى",
            "japanese": "يابانية",
            "hindi": "هندي",
            "turkish": "تركي",
            "korean": "كوري",
            "italian": "إيطالي",
            "dutch": "هولندي",
            "russian": "روسي",
            "indonesian": "إندونيسي",
            "alphabet": "الحروف الأبجدية",
            "numbers": "الأرقام",
            "learning": "التعلم",
            "languages": "اللغات",
            "selectLanguage": "اختر اللغة",
            "selectLearningType": "اختر نوع التعلم",
            "words": "الكلمات",
            "selectType": "اختر نوعا",
            "animals": "الحيوانات",
            "colors": "الألوان",
            "fruits": "الفواكه",
            "vegetables": "الخضروات",
            "foods": "أطعمة",
            "verbs": "أفعال",
            "houses": "منزل",
            "schools": "مدرسة",
            "supplies": "لوازم مدرسية",
            "natures": "الطبيعة",
            "places": "الأماكن والنقل",
            "occupations": "المهن",
            "iWantToLearn": "أريد أن أتعلم"
        }
    },
    de: {
        translation: {
            "playAll": "Alles abspielen",
            "conversationIn": "Gespräch in",
            "between": "zwischen",
            "and": "und",
            "subject": "Betreff",
            "conversation": "Gespräch",
            "copy": "KOPIEREN",
            "assistance": "Unterstützung",
            "answers": "Antworten",
            "explanations": "Analysieren und Erklären",
            "summary": "Zusammenfassung",
            "translation": "Die Übersetzung",
            "other": "Anderer Antrag",
            "other_example": "Beispiel: Analysiere dieses Dokument",
            "audio": "Audio",
            "mediaSource": "Um {{kind}} zu beantragen",
            "scan": "Dokument scannen",
            "upload": "Dokument hochladen",
            "text": "Text eingeben",
            "kind": "{{kind}} in Bearbeitung...",
            "responseLang": " in ",
            "question": "Geben Sie Ihre Frage ein",
            "send": "Senden",
            "chooseSourceType": "Ich suche",
            "comingSoon": "Demnächst verfügbar",
            "homeTitle": "Vereinfachen Sie Ihr Leben mit der Kraft von ChatGPT 4",
            "homeText": "Mit ChatGPT 4 und anderen KIs revolutionieren Sie Ihre Erfahrung mit unserer innovativen App! <br/>" +
                "Scannen, hochladen und Dokumente im Handumdrehen erkunden.<br/>" +
                "Chatten Sie in Text oder Sprache, erstellen Sie Dialoge und lernen Sie neue Sprachen mühelos.<br/>" +
                "Treten Sie jetzt bei! 🚀<br/>",
            "generateConversation": "Gespräch generieren",
            "voiceConversation": "Sprach-ChatGPT 4",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Bestimmte Frage",
            "conversations": "Meine Dialoge",
            "uploads": "Meine Uploads/Scans",
            "clear": "Löschen",
            "errorInternetConnection": "Fehler: Keine Internetverbindung, bitte versuchen Sie es später erneut.",
            "result": "Ergebnis",
            "resultDocument": "Antworten auf die Fragen des Dokuments erhalten.",
            "understandDocument": "Den Inhalt des Dokuments verstehen.",
            "summaryDocument": "Eine Zusammenfassung des Dokuments erhalten.",
            "downloadPdf": "PDF herunterladen",
            "unsatisfactoryResultMessage": "Wenn die Ergebnisse nicht zufriedenstellend sind, klicken Sie auf ",
            "imageUploadSuccess": "Das Bild wurde erfolgreich hochgeladen!",
            "downloadForEdition": "Herunterladen<br/>zum Bearbeiten",
            "resendImage": "Bild<br/>erneut senden",
            "delete": "Löschen",
            "retry": "Wiederholen",
            "imageLoading": "Bild wird geladen",
            "howItWork": "Wie es funktioniert",
            "retryIndication": "Wenn Ihr Dokument Übungen oder akademische Prüfungen enthält, kann KI sie nicht direkt verarbeiten. Um dies zu beheben, haben wir Ihr Dokument in Bilder umgewandelt, die Sie herunterladen und nach Bedarf bearbeiten können.<br/>" +
                "<br/>" +
                "Elemente, die aus dem Dokument entfernt werden sollten, sind:<br/><dl>" +
                "<dd>- Schullogo und Name</dd>" +
                "<dd>- Dokumententitel</dd>" +
                "<dd>- Punktzahl oder Bewertung für jede Frage</dd>" +
                "<dd>- Alle Informationen, die auf den Ursprung des Dokuments hinweisen</dd></dl><br/>" +
                "Wie weiter vorgehen:" +
                "<ol>" +
                "<li>Herunterladen zum Bearbeiten: Ermöglicht das Herunterladen des Bildes auf Ihr Gerät. Verwenden Sie eine Bildbearbeitungsanwendung wie Paint unter Windows.</li>" +
                "<li>Neues Bild hochladen: Ermöglicht das Hochladen des gerade bearbeiteten Bildes.</li>" +
                "<li>Löschen: In einigen Fällen müssen Sie das Bild löschen, wenn es keine Übungen enthält.</li>" +
                "<li>Nachdem die Bilder bearbeitet wurden, klicken Sie auf die Schaltfläche Wiederholen.</li></ol>",

            "history": "Verlauf",
            "originalDocument": "Originaldokument",
            "generatedImagesList": "Liste der generierten Bilder",
            "page": "Seite",
            "requestDate": "Anforderungsdatum",
            "usedPagesList": "Liste der verwendeten Seiten",
            "response": "Antwort",
            "noDownloadsAvailable": "Keine Downloads verfügbar",

            "home": "Startseite",
            "myChannel": "Mein Kanal",
            "subscriptions": "Abonnements",
            "hideSolution": "Lösung verbergen",
            "showSolution": "Lösung anzeigen",
            "makeVideoStory": "Ich erstelle meinen Zauberfilm",
            "makeAudioStory": "Ich entwerfe meine Hör-Geschichte",
            "startFunChallenge": "Ich löse geheimnisvolle Rätsel",
            "craftMyJokes": "Ich kreiere meine eigene Welt von Witzen",
            "watchStories": "Ich tauche in meine Filme ein",
            "listenToStories": "Ich entdecke meine wundervollen Geschichten",
            "funChallenges": "Ich stelle mich meinen fantastischen Herausforderungen",
            "exploreMyJokes": "Ich erkunde mein Universum von Witzen",
            "storyInPreparation": "Geschichte in Vorbereitung... Warum nicht",
            "listenAnotherStory": "hören Sie eine andere Geschichte",
            "watchAnotherStory": "sehen Sie eine andere Geschichte",
            "playARiddle": "ein Rätsel spielen",
            "giggleWithAnotherJoke": "Kichere mit einem anderen Witz",
            "pending": "steht aus",
            "myAccount": "Mein Konto",
            "signOut": "Abmelden",
            "signInWithGoogle": "Anmelden",
            "featureNotYetAvailable": "Funktion noch nicht verfügbar",
            "title": "Titel:",
            "yearsOld": "Jahre alt",
            "im": "Ich bin",
            "show": "Zeigen",
            "hide": "Verbergen",
            "showStory": "Geschichtentext anzeigen",
            "hideStory": "Geschichtentext verbergen",
            "surpriseMe": "Überrasche mich",
            "chooseForMe":"",
            "english":"Englisch",
            "spanish":"Spanisch",
            "arabic":"Arabisch",
            "french":"Französisch",
            "german":"Deutsch",
            "chinese":"Chinesisch",
            "japanese":"Japanisch",
            "hindi":"Hindi",
            "turkish":"Türkisch",
            "korean":"Koreanisch",
            "italian":"Italienisch",
            "dutch":"Niederländisch",
            "russian":"Russisch",
            "indonesian":"Indonesisch",
            "alphabet": "Alphabet",
            "numbers": "Zahlen",
            "learning": "Lernen",
            "languages": "Sprachen",
            "selectLanguage": "Wählen Sie eine Sprache",
            "selectLearningType": "Wählen Sie eine Lernart",
            "words": "Wörter",
            "selectType": "Wähle einen Typ",
            "animals": "Tiere",
            "colors": "Farben",
            "fruits": "Früchte",
            "vegetables": "Gemüse",
            "foods": "Lebensmittel",
            "verbs": "Verben",
            "houses": "Haus",
            "schools": "Schule",
            "supplies": "Schulmaterial",
            "natures": "Natur",
            "places": "Orte & Verkehrsmittel",
            "occupations": "Berufe",
            "iWantToLearn" : "Ich möchte lernen",
        }
    },
    fr: {
        translation: {
            "playAll": "Tout lire",
            "conversationIn": "Dialogue en",
            "between": "entre",
            "and": "et",
            "subject": "Sujet",
            "conversation": "Dialogue",

            "copy": "COPIER",
            "assistance": "Assist",
            "answers": " Les réponses",
            "explanations": "Analyse et Explication",
            "summary": "Le résumé",
            "translation": "La traduction",
            "other": "Autre demande",
            "other_example": "Exemple : Analyse ce document",
            "audio": "Audio",
            "mediaSource": "Pour demander {{kind}}",
            "scan": "Scanner un document",
            "upload": "Télécharger un document",
            "kind": "{{kind}} en préparation...",
            "responseLang": " en ",
            "question": "Saisir votre question",
            "send": "Envoyer",
            "chooseSourceType": "Je cherche",
            "comingSoon": "Bientôt disponible",

            "homeTitle": "Facilitez-vous la vie avec la puissance de ChatGPT 4",
            "homeText": "Grace à ChatGPT 4 et d'autres IA, transformez votre expérience avec notre appli novatrice ! <br/>" +
                "Scannez, téléchargez, et explorez des docs en un clin d'œil.<br/>" +
                "Discutez en texte ou en vocal, créez des dialogues et apprenez de nouvelles langues avec facilité.<br/>" +
                "Rejoignez-nous dès maintenant ! 🚀<br/>",
            "generateConversation": "Générer un dialogue",
            "voiceConversation": "ChatGPT 4 vocale",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Question précise",
            "conversations": "Mes dialogues",
            "uploads": "Mes téléchargements/scans",
            "clear": "Effacer",
            "errorInternetConnection": "Erreur : Aucune connexion à Internet, veuillez réessayer plus tard.",



            "result": "Résultat",
            "resultDocument": "Obtenir des réponses aux questions du document.",
            "understandDocument": "Comprendre le contenu du document.",
            "summaryDocument": "Obtenir un résumé du document.",
            "downloadPdf": "Télécharger le PDF",

            "unsatisfactoryResultMessage": "Si les résultats ne sont pas satisfaisants, cliquez sur ",
            "imageUploadSuccess": "L'image a été téléchargée avec succès!",
            "downloadForEdition": "Télécharger<br/>pour modification",
            "resendImage": "Renvoyer<br/>l'image",
            "delete": "Supprimer",
            "retry": "Réessayer",
            "imageLoading": "Image en cours de téléchargement",
            "howItWork": "Comment ça marche",
            "retryIndication": "Si votre document comprend des exercices ou des examens académiques, l'IA ne peut pas les traiter directement. Pour remédier à cela, nous avons converti votre document en images, que vous pouvez télécharger et modifier à votre convenance.<br/>" +
                "<br/>" +
                "Les éléments à supprimer du document incluent :<br/><dl>" +
                "<dd>- Le logo et le nom de l'établissement</dd>" +
                "<dd>- Le titre du document</dd>" +
                "<dd>- Le barème ou la notation de chaque question</dd>" +
                "<dd>- Toute information indiquant l'origine du document</dd></dl><br/>" +
                "Comment procéder :" +
                "<ol>" +
                "<li>Téléchargement pour modification : Permet de télécharger l'image sur votre appareil. Utilisez une application d'édition d'images, telle que Paint sur Windows.</li>" +
                "<li>Renvoi de l'image éditée : Permet de télécharger l'image que vous venez de modifier.</li>" +
                "<li>Suppression : Dans certains cas, vous devrez supprimer l'image si elle ne contient aucun exercice.</li>" +
                "<li>Une fois les images modifiées, cliquez sur le bouton Réessayer.</li></ol>",

            "history": "Historique",
            "originalDocument": "Document d'origine",
            "generatedImagesList": "Liste des images générées",
            "page": "Page",
            "requestDate": "Date de la demande",
            "usedPagesList": "Liste des pages utilisées",
            "response": "Réponse",
            "noDownloadsAvailable": "Aucun téléchargement disponible",

            "videoId1": "UG1uiPTwCFA",
            "videoId2": "8_ktC5Hn0r8",
            "videoLabel1": "Comment ça fonctionne ?",
            "videoLabel2": "Exploration et astuces",

            "story": "une histoire",
            "riddle": "un défi",
            "joke": "une blague",

            "tellStory": "Raconte moi {{kind}} sur",

            "sayAndClick": "Dis ce que tu veux puis click ici",
            "takePhotoAndClick": "Prends une photo de ton dessin et clique ici",
            "start": "Click ici pour commencer",

            "wildAnimals": "Exemple: Les animaux sauvages",
            "home": "Accueil",
            "myChannel": "Ma chaine",

            "subscriptions": "Abonnements",
            "hideSolution": "Masquer la solution",
            "showSolution": "Afficher la solution",

            "watchStories": "Je plonge dans mes films",
            "listenToStories": "Je découvre mes contes merveilleux",
            "funChallenges": "Je relève mes défis fantastiques",
            "exploreMyJokes": "J'explore mon univers de blagues",

            "listenAnotherStory": "écouter une autre histoire",
            "watchAnotherStory": "regaredr une autre histoire",
            "playARiddle": "jouer à une devinette",
            "giggleWithAnotherJoke": "rigole avec une autre blague",
            "pending": "en attendant",
            "myAccount": "Mon compte",
            "signOut": "Se déconnecter",
            "signInWithGoogle": "Se connecter",
            "featureNotYetAvailable": "Fonctionnalité pas encore disponible",
            "title": "Titre:",
            "yearsOld": "ans",
            "im": "J'ai",
            "show": "Afficher",
            "hide": "Cacher",
            "showStory": "Afficher le texte de l'histoire",
            "hideStory": "Cacher le texte de l'histoire",
            "surpriseMe": "Surprends-moi",

            "english":"Anglais",
            "spanish":"Espagnol",
            "arabic":"Arabe",
            "french":"Français",
            "german":"Allemand",
            "chinese":"Chinois",
            "japanese":"Japonais",
            "hindi":"Hindi",
            "turkish":"Turc",
            "korean":"Coréen",
            "italian":"Italien",
            "dutch":"Néerlandais",
            "russian":"Russe",
            "indonesian":"Indonésien",
            "alphabet": "Alphabet",
            "numbers": "Nombres",
            "learning": "Apprentissage",
            "languages": "Langues",
            "selectLanguage": "Sélectionne une langue",
            "selectLearningType": "Sélectionne un type d'apprentissage",
            "words": "Mots",
            "selectType": "Sélectionnez un type",
            "animals": "Animaux",
            "colors": "Couleurs",
            "fruits": "Fruits",
            "vegetables": "Légumes",
            "foods": "Aliments",
            "verbs": "Verbes",
            "houses": "Maison",
            "schools": "École",
            "supplies": "Fournitures scolaires",
            "natures": "Nature",
            "places": "Lieux et Transports",
            "occupations": "Métiers",
            "iWantToLearn" : "Je veux apprendre",
        }
    },
    zh: {
        translation: {
            "playAll": "播放全部",
            "conversationIn": "对话在",
            "between": "之间",
            "and": "和",
            "subject": "主题",
            "conversation": "对话",
            "copy": "复制",
            "assistance": "帮助",
            "answers": "答案",
            "explanations": "分析和解释",
            "summary": "摘要",
            "translation": "翻译",
            "other": "其他请求",
            "other_example": "示例：分析此文档",
            "audio": "音频",
            "mediaSource": "申请{{kind}}",
            "scan": "扫描文档",
            "upload": "上传文档",
            "text": "输入文本",
            "kind": "{{kind}} 正在制作中...",
            "responseLang": " 在 ",
            "question": "输入您的问题",
            "send": "发送",
            "chooseSourceType": "我在寻找",
            "comingSoon": "即将推出",
            "homeTitle": "利用ChatGPT 4的力量简化您的生活",
            "homeText": "通过ChatGPT 4和其他人工智能，用我们创新的应用程序彻底改变您的体验！<br/>" +
                "扫描、上传、瞬间探索文档。<br/>" +
                "通过文字或语音聊天，创建对话，并轻松学习新语言。<br/>" +
                "立即加入我们！🚀<br/>",
            "generateConversation": "生成对话",
            "voiceConversation": "语音ChatGPT 4",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "特定问题",
            "conversations": "我的对话",
            "uploads": "我的上传/扫描",
            "clear": "清除",
            "errorInternetConnection": "错误：没有网络连接，请稍后重试。",
            "result": "结果",
            "resultDocument": "获取文档问题的答案。",
            "understandDocument": "理解文档内容。",
            "summaryDocument": "获取文档摘要。",
            "downloadPdf": "下载 PDF",
            "unsatisfactoryResultMessage": "如果结果不满意，请点击 ",
            "imageUploadSuccess": "图片上传成功!",
            "downloadForEdition": "下载<br/>进行编辑",
            "resendImage": "重新发送<br/>图片",
            "delete": "删除",
            "retry": "重试",
            "imageLoading": "图片加载中",
            "howItWork": "它是如何工作的",
            "retryIndication": "如果您的文档包含练习或学术考试，AI 无法直接处理它们。为解决此问题，我们已将您的文档转换为图像，您可以下载并根据需要进行编辑。<br/>" +
                "<br/>" +
                "从文档中删除的元素包括：<br/><dl>" +
                "<dd>- 学校徽标和名称</dd>" +
                "<dd>- 文档标题</dd>" +
                "<dd>- 每个问题的评分或分级</dd>" +
                "<dd>- 表明文档来源的任何信息</dd></dl><br/>" +
                "操作步骤：" +
                "<ol>" +
                "<li>下载以进行编辑：可将图像下载到您的设备上。使用图像编辑应用程序，例如 Windows 上的 Paint。</li>" +
                "<li>重新发送编辑后的图像：可上传刚刚修改的图像。</li>" +
                "<li>删除：在某些情况下，如果图像不包含任何练习，您可能需要删除它。</li>" +
                "<li>编辑完成后，单击“重试”按钮。</li></ol>",

            "history": "历史",
            "originalDocument": "原始文件",
            "generatedImagesList": "生成图像列表",
            "page": "页面",
            "requestDate": "请求日期",
            "usedPagesList": "已使用页面列表",
            "response": "响应",
            "noDownloadsAvailable": "无可用下载",

            "start": "点击这里开始",
            "home": "主页",
            "myChannel": "我的频道",
            "subscriptions": "订阅",
            "hideSolution": "隐藏解决方案",
            "showSolution": "显示解决方案",
            "makeVideoStory": "我创建我的魔术电影",
            "makeAudioStory": "我设计我的声音故事",
            "startFunChallenge": "我解决神秘的谜语",
            "craftMyJokes": "我创造了我自己的笑话世界",
            "watchStories": "我沉浸在我的电影中",
            "listenToStories": "我发现我的奇妙故事",
            "funChallenges": "我面临我精彩的挑战",
            "exploreMyJokes": "我探索我的笑话的宇宙",
            "storyInPreparation": "故事准备中... 为什么不",
            "listenAnotherStory": "听另一个故事",
            "watchAnotherStory": "看另一个故事",
            "playARiddle": "玩一个谜语",
            "giggleWithAnotherJoke": "和另一个笑话一起咯咯笑",
            "pending": "待定",
            "myAccount": "我的账户",
            "signOut": "登出",
            "signInWithGoogle": "登录",
            "featureNotYetAvailable": "功能尚未提供",
            "title": "标题:",
            "yearsOld": "岁",
            "im": "我",
            "show": "显示",
            "hide": "隐藏",
            "showStory": "显示故事文本",
            "hideStory": "隐藏故事文本",
            "surpriseMe": "给我一个惊喜",
            "chooseForMe":"",
            "english":"英语",
            "spanish":"西班牙语",
            "arabic":"阿拉伯语",
            "french":"法语",
            "german":"德语",
            "chinese":"中文",
            "japanese":"日语",
            "hindi":"印地语",
            "turkish":"土耳其语",
            "korean":"韩语",
            "italian":"意大利语",
            "dutch":"荷兰语",
            "russian":"俄语",
            "indonesian":"印度尼西亚语",
            "alphabet": "字母表",
            "numbers": "数字",
            "learning": "学习",
            "languages": "语言",
            "selectLanguage": "选择语言",
            "selectLearningType": "选择学习类型",
            "words": "单词",
            "selectType": "选择类型",
            "animals": "动物",
            "colors": "颜色",
            "fruits": "水果",
            "vegetables": "蔬菜",
            "foods": "食物",
            "verbs": "动词",
            "houses": "房子",
            "schools": "学校",
            "supplies": "学校用品",
            "natures": "自然",
            "places": "地点与交通",
            "occupations": "职业",
            "iWantToLearn" : "我想学习",
        }
    },
    hi: {
        translation: {
            "playAll": "सभी चलाएं",
            "conversationIn": "संवाद में",
            "between": "के बीच",
            "and": "और",
            "subject": "विषय",
            "conversation": "संवाद",
            "copy": "कॉपी",
            "assistance": "सहायता",
            "answers": "उत्तर",
            "explanations": "विश्लेषण और स्पष्टीकरण",
            "summary": "संक्षेप",
            "translation": "अनुवाद",
            "other": "अन्य अनुरोध",
            "other_example": "उदाहरण: इस दस्तावेज़ का विश्लेषण करें",
            "audio": "ऑडियो",
            "mediaSource": "{{kind}} का अनुरोध करने के लिए",
            "scan": "एक दस्तावेज़ स्कैन करें",
            "upload": "एक दस्तावेज़ अपलोड करें",
            "text": "टेक्स्ट दर्ज करें",
            "kind": "{{kind}} तैयार हो रहा है...",
            "responseLang": " में ",
            "question": "अपना सवाल दर्ज करें",
            "send": "भेजें",
            "chooseSourceType": "मैं खोज रहा हूँ",
            "comingSoon": "जल्द ही उपलब्ध होगा",
            "homeTitle": "चैटजीपीटी 4 की ताकत से अपने जीवन को सरल बनाएं",
            "homeText": "चैटजीपीटी 4 और अन्य एआई के साथ, हमारे नवाचारी ऐप के साथ अपने अनुभव को क्रांति लाएं! <br/>" +
                "दस्तावेज़ों को स्कैन, अपलोड और आँख मारकर देखें।<br/>" +
                "टेक्स्ट या आवाज़ में चैट करें, डायलॉग बनाएं और नई भाषाएँ आसानी से सीखें।<br/>" +
                "अभी हमारे साथ जुड़ें! 🚀<br/>",
            "generateConversation": "बातचीत उत्पन्न करें",
            "voiceConversation": "ध्वनि चैटजीपीटी 4",
            "textConversation": "चैटजीपीटी 4",
            "specificQuestion": "निर्दिष्ट प्रश्न",
            "conversations": "मेरी बातचीतें",
            "uploads": "मेरे अपलोड/स्कैन",
            "clear": "साफ करें",
            "errorInternetConnection": "त्रुटि: कोई इंटरनेट कनेक्शन नहीं है, कृपया बाद में पुन: प्रयास करें।",
            "result": "परिणाम",
            "resultDocument": "दस्तावेज़ के प्रश्नों का उत्तर प्राप्त करें।",
            "understandDocument": "दस्तावेज़ की सामग्री को समझें।",
            "summaryDocument": "दस्तावेज़ का संक्षेप प्राप्त करें।",
            "downloadPdf": "PDF डाउनलोड करें",
            "unsatisfactoryResultMessage": "यदि परिणाम संतुष्टकर नहीं हैं, यहाँ क्लिक करें ",
            "imageUploadSuccess": "छवि सफलता से अपलोड हो गई है!",
            "downloadForEdition": "डाउनलोड करें<br/>संपादन के लिए",
            "resendImage": "फिर से भेजें<br/>छवि",
            "delete": "हटाएं",
            "retry": "पुनः प्रयास करें",
            "imageLoading": "छवि लोड हो रही है",
            "howItWork": "यह कैसे काम करता है",
            "retryIndication": "यदि आपके दस्तावेज में अभ्यास या एकेडमिक परीक्षा शामिल हैं, तो ए.आई. उन्हें सीधे प्रोसेस करने में सक्षम नहीं है। इस समस्या का समाधान करने के लिए, हमने आपके दस्तावेज को छवियों में बदल दिया है, जिसे आप आवश्यकता के हिसाब से डाउनलोड करके संपादित कर सकते हैं।<br/>" +
                "<br/>" +
                "दस्तावेज से हटाने के लिए तत्व शामिल हैं:<br/><dl>" +
                "<dd>- स्कूल का लोगो और नाम</dd>" +
                "<dd>- दस्तावेज का शीर्षक</dd>" +
                "<dd>- प्रत्येक सवाल के लिए स्कोरिंग या ग्रेडिंग</dd>" +
                "<dd>- दस्तावेज के स्रोत को सूचित करने वाली कोई भी जानकारी</dd></dl><br/>" +
                "कैसे आगे बढ़ें:" +
                "<ol>" +
                "<li>संपादन के लिए डाउनलोड: इससे आप छवि को अपने डिवाइस पर डाउनलोड कर सकते हैं। Windows पर पेंट जैसे छवि संपादन एप्लिकेशन का उपयोग करें।</li>" +
                "<li>संपादित छवि को पुनः भेजें: इससे आप अपने हाल ही में संशोधित किए गए छवि को अपलोड कर सकते हैं।</li>" +
                "<li>हटाएँ: कुछ स्थितियों में, यदि छवि में कोई अभ्यास नहीं है, तो आपको छवि को हटाना हो सकता है।</li>" +
                "<li>छवियों को संपादित करने के बाद, 'पुनः प्रयास करें' बटन पर क्लिक करें।</li></ol>",

            "history": "इतिहास",
            "originalDocument": "मौलिक दस्तावेज़",
            "generatedImagesList": "निर्मित छवियों की सूची",
            "page": "पृष्ठ",
            "requestDate": "अनुरोध तिथि",
            "usedPagesList": "उपयोग किए गए पृष्ठों की सूची",
            "response": "प्रतिक्रिया",
            "noDownloadsAvailable": "कोई डाउनलोड उपलब्ध नहीं है",

            "start": "शुरू करने के लिए यहां क्लिक करें",
            "wildAnimals": "उदाहरण: जंगली जानवर",
            "home": "होम",
            "myChannel": "मेरा चैनल",
            "subscriptions": "सदस्यताएं",
            "hideSolution": "समाधान छुपाएं",
            "showSolution": "समाधान दिखाएं",
            "makeVideoStory": "मैं अपनी जादुई फिल्म बनाता हूँ",
            "makeAudioStory": "मैं अपनी ऑडियो कहानी का डिजाइन करता हूं",
            "startFunChallenge": "मैं रहस्यमय पहेलियाँ सुलझाता हूँ",
            "craftMyJokes": "मैं अपनी जोक्स की दुनिया बनाता हूं",
            "watchStories": "मैं अपनी फिल्मों में डूब जाता हूँ",
            "listenToStories": "मैं अपनी अद्भुत कथाएं खोजता हूँ",
            "funChallenges": "मैं अपनी आश्चर्यजनक चुनौतियों का सामना करता हूँ",
            "exploreMyJokes": "मैं अपने चुटकुले का ब्रह्मांड अन्वेषण करता हूं",
            "storyInPreparation": "कहानी तैयारी में... क्यों नहीं",
            "listenAnotherStory": "एक और कहानी सुनें",
            "watchAnotherStory": "एक और कहानी देखें",
            "playARiddle": "एक पहेली खेलें",
            "giggleWithAnotherJoke": "एक और मजाक के साथ हंसिये",
            "pending": "लंबित",
            "myAccount": "मेरा खाता",
            "signOut": "साइन आउट",
            "signInWithGoogle": "लॉग इन करें",
            "featureNotYetAvailable": "सुविधा अभी तक उपलब्ध नहीं है",
            "title": "शीर्षक:",
            "yearsOld": "साल के",
            "im": "मेरी उम्र",
            "show": "दिखाएं",
            "hide": "छुपाएं",
            "showStory": "कहानी का पाठ दिखाएं",
            "hideStory": "कहानी का पाठ छुपाएं",
            "surpriseMe": "मुझे चौंका दो",
            "chooseForMe":"",
            "english":"अंग्रेज़ी",
            "spanish":"स्पेनिश",
            "arabic":"अरबी",
            "french":"फ़्रेंच",
            "german":"जर्मन",
            "chinese":"चीनी",
            "japanese":"जापानी",
            "hindi":"हिंदी",
            "turkish":"तुर्की",
            "korean":"कोरियाई",
            "italian":"इटालियन",
            "dutch":"डच",
            "russian":"रूसी",
            "indonesian":"इंडोनेशियाई",
            "alphabet": "वर्णमाला",
            "numbers": "संख्याएँ",
            "learning": "सीखना",
            "languages": "भाषाएं",
            "selectLanguage": "एक भाषा चुनें",
            "selectLearningType": "एक सीखने का प्रकार चुनें",
            "words": "शब्द",
            "selectType": "प्रकार चुनें",
            "animals": "जानवर",
            "colors": "रंग",
            "fruits": "फल",
            "vegetables": "सब्ज़ियाँ",
            "foods": "खाना",
            "verbs": "क्रियाएँ",
            "houses": "घर",
            "schools": "स्कूल",
            "supplies": "स्कूल सामग्री",
            "natures": "प्रकृति",
            "places": "स्थान और परिवहन",
            "occupations": "व्यापार",
            "iWantToLearn" : "मैं सीखना चाहता हूँ",
        }
    },
    ja: {
        translation: {
            "playAll": "すべて再生",
            "conversationIn": "会話中",
            "between": "と", "and": "と",
            "subject": "件名",
            "conversation": "会話",
            "copy": "コピー",
            "assistance": "アシスタンス",
            "answers": "回答",
            "explanations": "分析と説明",
            "summary": "要約",
            "translation": "翻訳",
            "other": "他の要求",
            "other_example": "例: この文書を分析する",
            "audio": "オーディオ",
            "mediaSource": "{{kind}}のリクエスト",
            "scan": "ドキュメントのスキャン",
            "upload": "ドキュメントのアップロード",
            "text": "テキストの入力",
            "kind": "{{kind}} 進行中...",
            "responseLang": " で ",
            "question": "質問を入力",
            "send": "送信",
            "chooseSourceType": "私は探しています",
            "comingSoon": "近日公開",
            "homeTitle": "ChatGPT 4の力で生活を簡素化する",
            "homeText": "ChatGPT 4および他のAIを使用して、革新的なアプリで体験を革新しましょう！<br/>" +
                "文書をスキャンし、アップロードして、瞬時に探索します。<br/>" +
                "テキストまたは音声でチャットし、対話を作成し、新しい言語を簡単に学びます。<br/>" +
                "今すぐ参加してください！ 🚀<br/>",
            "generateConversation": "対話を生成する",
            "voiceConversation": "音声ChatGPT 4",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "特定の質問",
            "conversations": "私の対話",
            "uploads": "私のアップロード/スキャン",
            "clear": "クリア",
            "errorInternetConnection": "エラー：インターネットに接続されていません。後でもう一度お試しください。",
            "result": "結果",
            "resultDocument": "ドキュメントの質問に回答を得る。",
            "understandDocument": "ドキュメントの内容を理解する。",
            "summaryDocument": "ドキュメントの要約を得る。",
            "downloadPdf": "PDFをダウンロード",
            "unsatisfactoryResultMessage": "結果が満足でない場合は、クリックしてください ",
            "imageUploadSuccess": "画像が正常にアップロードされました！",
            "downloadForEdition": "ダウンロード<br/>編集用",
            "resendImage": "画像を<br/>再送信",
            "delete": "削除",
            "retry": "再試行",
            "imageLoading": "画像の読み込み中",
            "howItWork": "使い方",
            "retryIndication": "あなたの文書に練習や学術試験が含まれている場合、AIはそれらを直接処理できません。これを解決するために、文書を画像に変換し、必要に応じてダウンロードして編集できるようにしました。<br/>" +
                "<br/>" +
                "文書から削除する要素には次のものがあります：<br/><dl>" +
                "<dd>- 学校のロゴと名前</dd>" +
                "<dd>- 文書のタイトル</dd>" +
                "<dd>- 各質問のスコアリングまたは採点</dd>" +
                "<dd>- 文書の出典を示す任意の情報</dd></dl><br/>" +
                "進め方：" +
                "<ol>" +
                "<li>編集のためにダウンロード：画像をデバイスにダウンロードできます。WindowsのPaintなどの画像編集アプリケーションを使用してください。</li>" +
                "<li>編集済み画像を再送信：編集したばかりの画像をアップロードできます。</li>" +
                "<li>削除：場合によっては、演習が含まれていない場合は画像を削除する必要があります。</li>" +
                "<li>画像が編集されたら、「リトライ」ボタンをクリックしてください。</li></ol>",

            "history": "履歴",
            "originalDocument": "原本の文書",
            "generatedImagesList": "生成された画像のリスト",
            "page": "ページ",
            "requestDate": "リクエスト日",
            "usedPagesList": "使用されたページのリスト",
            "response": "応答",
            "noDownloadsAvailable": "ダウンロードはありません",

            "start": "ここをクリックして開始してください",
            "wildAnimals": "例：野生動物",
            "home": "ホーム",
            "myChannel": "マイチャンネル",
            "subscriptions": "購読",
            "hideSolution": "解決策を隠す",
            "showSolution": "解決策を表示",
            "makeVideoStory": "私は魔法の映画を作ります",
            "makeAudioStory": "私は自分のオーディオ物語をデザインします",
            "startFunChallenge": "私は謎めいたパズルを解きます",
            "craftMyJokes": "私は自分だけのジョークの世界を作り出します",
            "watchStories": "私は自分の映画に没頭します",
            "listenToStories": "私は素晴らしい話を発見します",
            "funChallenges": "私は素晴らしい挑戦に立ち向かいます",
            "exploreMyJokes": "私のジョークの宇宙を探索する",
            "storyInPreparation": "ストーリーの準備中... なぜなら",
            "listenAnotherStory": "別の物語を聞く",
            "watchAnotherStory": "別の物語を見る",
            "playARiddle": "謎を遊ぶ",
            "giggleWithAnotherJoke": "別のジョークでくすくす笑う",
            "pending": "保留中",
            "myAccount": "マイアカウント",
            "signOut": "サインアウト",
            "signInWithGoogle": "ログイン",
            "featureNotYetAvailable": "まだ利用できない機能",
            "title": "タイトル:",
            "yearsOld": "歳",
            "im": "私は",
            "show": "表示する",
            "hide": "隠す",
            "showStory": "ストーリーテキストを表示する",
            "hideStory": "ストーリーテキストを隠す",
            "surpriseMe": "驚かせてください",
            "chooseForMe":"",
            "english":"英語",
            "spanish":"スペイン語",
            "arabic":"アラビア語",
            "french":"フランス語",
            "german":"ドイツ語",
            "chinese":"中国語",
            "japanese":"日本語",
            "hindi":"ヒンディー語",
            "turkish":"トルコ語",
            "korean":"韓国語",
            "italian":"イタリア語",
            "dutch":"オランダ語",
            "russian":"ロシア語",
            "indonesian":"インドネシア語",
            "alphabet": "アルファベット",
            "numbers": "数字",
            "learning": "学習",
            "languages": "言語",
            "selectLanguage": "言語を選択",
            "selectLearningType": "学習タイプを選択",
            "words": "言葉",
            "selectType": "タイプを選択",
            "animals": "動物",
            "colors": "色",
            "fruits": "果物",
            "vegetables": "野菜",
            "foods": "食べ物",
            "verbs": "動詞",
            "houses": "家",
            "schools": "学校",
            "supplies": "学校の用品",
            "natures": "自然",
            "places": "場所と交通",
            "occupations": "職業",
            "iWantToLearn" : "私は学びたい",
        }
    },
    it: {
        translation: {
            "playAll": "Riproduci tutto",
            "conversationIn": "Conversazione in",
            "between": "tra",
            "and": "e",
            "subject": "Oggetto",
            "conversation": "Conversazione",
            "copy": "COPIA",
            "assistance": "Assistenza",
            "answers": "Risposte",
            "explanations": "Analisi ed Esplicazione",
            "summary": "Sommario",
            "translation": "La traduzione",
            "other": "Altro richiesta",
            "other_example": "Esempio: Analizza questo documento",
            "audio": "Audio",
            "mediaSource": "Per richiedere {{kind}}",
            "scan": "Scansiona un documento",
            "upload": "Carica un documento",
            "text": "Inserisci un testo",
            "kind": "{{kind}} in corso...",
            "responseLang": " in ",
            "question": "Inserisci la tua domanda",
            "send": "Invia",
            "chooseSourceType": "Sto cercando",
            "comingSoon": "Prossimamente disponibile",
            "homeTitle": "Semplifica la tua vita con la potenza di ChatGPT 4",
            "homeText": "Con ChatGPT 4 e altre IA, rivoluziona la tua esperienza con la nostra innovativa app! <br/>" +
                "Scansiona, carica ed esplora documenti in un batter d'occhio.<br/>" +
                "Chatta in testo o voce, crea dialoghi e impara nuove lingue con facilità.<br/>" +
                "Unisciti a noi ora! 🚀<br/>",
            "generateConversation": "Genera conversazione",
            "voiceConversation": "ChatGPT 4 vocale",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Domanda specifica",
            "conversations": "Le mie conversazioni",
            "uploads": "I miei upload/scansioni",
            "clear": "Pulisci",
            "errorInternetConnection": "Errore: Nessuna connessione a internet, si prega di riprovare più tardi.",
            "result": "Risultato",
            "resultDocument": "Ottenere risposte alle domande del documento.",
            "understandDocument": "Capire il contenuto del documento.",
            "summaryDocument": "Ottenere un riassunto del documento.",
            "downloadPdf": "Scarica il PDF",
            "unsatisfactoryResultMessage": "Se i risultati non sono soddisfacenti, clicca su ",
            "imageUploadSuccess": "Immagine caricata con successo!",
            "downloadForEdition": "Scarica<br/>per modificare",
            "resendImage": "Rinvia<br/>l'immagine",
            "delete": "Elimina",
            "retry": "Riprova",
            "imageLoading": "Caricamento immagine",
            "howItWork": "Come funziona",
            "retryIndication": "Se il tuo documento include esercizi o esami accademici, l'IA non può elaborarli direttamente. Per ovviare a ciò, abbiamo convertito il tuo documento in immagini, che puoi scaricare e modificare secondo necessità.<br/>" +
                "<br/>" +
                "Gli elementi da rimuovere dal documento includono:<br/><dl>" +
                "<dd>- Logo e nome della scuola</dd>" +
                "<dd>- Titolo del documento</dd>" +
                "<dd>- Punteggio o valutazione per ogni domanda</dd>" +
                "<dd>- Qualsiasi informazione che indichi l'origine del documento</dd></dl><br/>" +
                "Come procedere:" +
                "<ol>" +
                "<li>Scarica per la modifica: ti consente di scaricare l'immagine sul tuo dispositivo. Usa un'applicazione di modifica immagini, come Paint su Windows.</li>" +
                "<li>Rispedisci l'immagine modificata: ti consente di caricare l'immagine appena modificata.</li>" +
                "<li>Cancellazione: In alcuni casi, potrebbe essere necessario cancellare l'immagine se non contiene esercizi.</li>" +
                "<li>Dopo aver modificato le immagini, clicca su Riprova.</li></ol>",

            "history": "Cronologia",
            "originalDocument": "Documento originale",
            "generatedImagesList": "Lista delle immagini generate",
            "page": "Pagina",
            "requestDate": "Data della richiesta",
            "usedPagesList": "Lista delle pagine utilizzate",
            "response": "Risposta",
            "noDownloadsAvailable": "Nessun download disponibile",

            "start": "Clicca qui per iniziare",
            "wildAnimals": "Esempio: Animali selvaggi",
            "home": "Home",
            "myChannel": "Il mio canale",
            "subscriptions": "Abbonamenti",
            "hideSolution": "Nascondi la soluzione",
            "showSolution": "Mostra la soluzione",
            "makeVideoStory": "Creo il mio film magico",
            "makeAudioStory": "Progetto il mio racconto sonoro",
            "startFunChallenge": "Risolvo enigmi misteriosi",
            "craftMyJokes": "Creo il mio mondo di barzellette",
            "watchStories": "Mi immergo nei miei film",
            "listenToStories": "Scopro i miei racconti meravigliosi",
            "funChallenges": "Affronto le mie sfide fantastiche",
            "exploreMyJokes": "Esploro il mio universo di scherzi",
            "storyInPreparation": "Storia in preparazione... Perché no",
            "listenAnotherStory": "ascolta un'altra storia",
            "watchAnotherStory": "guarda un'altra storia",
            "playARiddle": "gioca un indovinello",
            "giggleWithAnotherJoke": "Ridere con un altro scherzo",
            "pending": "in attesa",
            "myAccount": "Il mio account",
            "signOut": "Esci",
            "signInWithGoogle": "Accedi",
            "featureNotYetAvailable": "Funzionalità non ancora disponibile",
            "title": "Titolo:",
            "yearsOld": "anni",
            "im": "Ho",
            "show": "Mostra",
            "hide": "Nascondi",
            "showStory": "Mostra il testo della storia",
            "hideStory": "Nascondi il testo della storia",
            "surpriseMe": "Sorprendimi",
            "chooseForMe":"",
            "english":"Inglese",
            "spanish":"Spagnolo",
            "arabic":"Arabo",
            "french":"Francese",
            "german":"Tedesco",
            "chinese":"Cinese",
            "japanese":"Giapponese",
            "hindi":"Hindi",
            "turkish":"Turco",
            "korean":"Coreano",
            "italian":"Italiano",
            "dutch":"Olandese",
            "russian":"Russo",
            "indonesian":"Indonesiano",
            "alphabet": "Alfabeto",
            "numbers": "Numeri",
            "learning": "Apprendimento",
            "languages": "Lingue",
            "selectLanguage": "Seleziona una lingua",
            "selectLearningType": "Seleziona il tipo di apprendimento",
            "words": "Parole",
            "selectType": "Seleziona un tipo",
            "animals": "Animali",
            "colors": "Colori",
            "fruits": "Frutta",
            "vegetables": "Verdure",
            "foods": "Cibi",
            "verbs": "Verbi",
            "houses": "Casa",
            "schools": "Scuola",
            "supplies": "Forniture scolastiche",
            "natures": "Natura",
            "places": "Luoghi e Trasporti",
            "occupations": "Professioni",
            "iWantToLearn" : "Voglio imparare",
        }
    },
    ko: {
        translation: {
            "playAll": "모두 재생",
            "conversationIn": "대화 중",
            "between": "사이",
            "and": "그리고",
            "subject": "주제",
            "conversation": "대화",
            "copy": "복사",
            "assistance": "지원",
            "answers": "답변",
            "explanations": "분석 및 설명",
            "summary": "요약",
            "translation": "번역",
            "other": "다른 요청",
            "other_example": "예제: 이 문서를 분석하세요",
            "audio": "오디오",
            "mediaSource": "{{kind}} 요청",
            "scan": "문서 스캔",
            "upload": "문서 업로드",
            "text": "텍스트 입력",
            "kind": "{{kind}} 진행 중...",
            "responseLang": " 언어 ",
            "question": "질문 입력",
            "send": "보내기",
            "chooseSourceType": "찾고 있어요",
            "comingSoon": "곧 출시 예정",
            "homeTitle": "ChatGPT 4의 힘으로 삶을 간편하게 만들기",
            "homeText": "ChatGPT 4 및 다른 AI를 사용하여 혁신적인 앱으로 경험을 혁신하세요! <br/>" +
                "문서를 스캔하고 업로드하고 순식간에 탐색합니다.<br/>" +
                "텍스트 또는 음성으로 채팅하고 대화를 만들며 쉽게 새로운 언어를 배우세요.<br/>" +
                "지금 바로 참여하세요! 🚀<br/>",
            "generateConversation": "대화 생성",
            "voiceConversation": "음성 ChatGPT 4",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "구체적인 질문",
            "conversations": "내 대화",
            "uploads": "내 업로드/스캔",
            "clear": "지우기",
            "errorInternetConnection": "오류: 인터넷 연결 없음. 나중에 다시 시도하십시오.",
            "result": "결과",
            "resultDocument": "문서의 질문에 대한 답변 얻기.",
            "understandDocument": "문서 내용 이해하기.",
            "summaryDocument": "문서 요약 얻기.",
            "downloadPdf": "PDF 다운로드",
            "unsatisfactoryResultMessage": "만족스럽지 않은 결과인 경우, 다음을 클릭하세요 ",
            "imageUploadSuccess": "이미지가 성공적으로 업로드되었습니다!",
            "downloadForEdition": "다운로드<br/>편집용",
            "resendImage": "이미지<br/>다시 보내기",
            "delete": "삭제",
            "retry": "재시도",
            "imageLoading": "이미지 로딩 중",
            "howItWork": "작동 방식",
            "retryIndication": "문서에 연습 문제 또는 학문 시험이 포함된 경우, AI는 직접 처리할 수 없습니다. 이를 해결하기 위해 문서를 이미지로 변환하여 다운로드하고 필요에 따라 편집할 수 있도록 했습니다.<br/>" +
                "<br/>" +
                "문서에서 제거해야 할 항목은 다음과 같습니다:<br/><dl>" +
                "<dd>- 학교 로고 및 이름</dd>" +
                "<dd>- 문서 제목</dd>" +
                "<dd>- 각 질문에 대한 점수 또는 평가</dd>" +
                "<dd>- 문서의 출처를 나타내는 모든 정보</dd></dl><br/>" +
                "진행 방법:" +
                "<ol>" +
                "<li>편집용으로 다운로드: 이미지를 기기에 다운로드할 수 있습니다. Windows의 Paint와 같은 이미지 편집 애플리케이션을 사용하세요.</li>" +
                "<li>편집된 이미지 재전송: 방금 수정한 이미지를 업로드할 수 있습니다.</li>" +
                "<li>삭제: 일부 경우에는 연습 문제가 포함되어 있지 않은 경우 이미지를 삭제해야 할 수 있습니다.</li>" +
                "<li>이미지를 수정한 후 '다시 시도' 버튼을 클릭하세요.</li></ol>",

            "history": "연혁",
            "originalDocument": "원본 문서",
            "generatedImagesList": "생성된 이미지 목록",
            "page": "페이지",
            "requestDate": "요청 날짜",
            "usedPagesList": "사용된 페이지 목록",
            "response": "응답",
            "noDownloadsAvailable": "다운로드 없음",

            "start": "시작하려면 여기를 클릭하세요",
            "wildAnimals": "예시: 야생동물",
            "home": "홈",
            "myChannel": "내 채널",
            "subscriptions": "구독",
            "hideSolution": "해결책 숨기기",
            "showSolution": "해결책 보이기",
            "makeVideoStory": "나는 내 마법의 영화를 만든다",
            "makeAudioStory": "나는 나만의 오디오 이야기를 디자인합니다",
            "startFunChallenge": "나는 수수께끼를 풀어낸다",
            "craftMyJokes": "나는 나만의 농담 세계를 만들어요",
            "watchStories": "나는 내 영화에 빠져든다",
            "listenToStories": "나는 내 놀라운 이야기를 발견한다",
            "funChallenges": "나는 내 환상적인 도전을 만난다",
            "exploreMyJokes": "나는 내 농담의 우주를 탐험한다",
            "storyInPreparation": "이야기 준비 중... 왜 안돼",
            "listenAnotherStory": "다른 이야기 듣기",
            "watchAnotherStory": "다른 이야기 보기",
            "playARiddle": "수수께끼를 풀다",
            "giggleWithAnotherJoke": "또 다른 농담으로 킥킥 거리다",
            "pending": "대기 중",
            "myAccount": "내 계정",
            "signOut": "로그 아웃",
            "signInWithGoogle": "로그인",
            "featureNotYetAvailable": "아직 사용할 수 없는 기능",
            "title": "제목:",
            "yearsOld": "세",
            "im": "나는",
            "show": "보이기",
            "hide": "숨기기",
            "showStory": "스토리 텍스트 표시",
            "hideStory": "스토리 텍스트 숨기기",
            "surpriseMe": "놀라게 해주세요",
            "chooseForMe":"",
            "english":"영어",
            "spanish":"스페인어",
            "arabic":"아라비아어",
            "french":"프랑스어",
            "german":"독일어",
            "chinese":"중국어",
            "japanese":"일본어",
            "hindi":"힌디어",
            "turkish":"터키어",
            "korean":"한국어",
            "italian":"이탈리아어",
            "dutch":"네덜란드어",
            "russian":"러시아어",
            "indonesian":"인도네시아어",
            "alphabet": "알파벳",
            "numbers": "숫자들",
            "learning": "학습",
            "languages": "언어",
            "selectLanguage": "언어 선택",
            "selectLearningType": "학습 유형 선택",
            "words": "단어",
            "selectType": "타입 선택",
            "animals": "동물",
            "colors": "색깔",
            "fruits": "과일",
            "vegetables": "채소",
            "foods": "음식",
            "verbs": "동사",
            "houses": "집",
            "schools": "학교",
            "supplies": "학교 용품",
            "natures": "자연",
            "places": "장소와 교통",
            "occupations": "직업",
            "iWantToLearn" : "나는 배우고 싶다",
        }
    },
    nl: {
        translation: {
            "playAll": "Alles afspelen",
            "conversationIn": "Gesprek in",
            "between": "tussen",
            "and": "en",
            "subject": "Onderwerp",
            "conversation": "Gesprek",
            "copy": "KOPIËREN",
            "assistance": "Ondersteuning",
            "answers": "Antwoorden",
            "explanations": "Analyseren en Uitleggen",
            "summary": "Samenvatting",
            "translation": "De vertaling",
            "other": "Andere aanvraag",
            "other_example": "Voorbeeld: Analyseer dit document",
            "audio": "Audio",
            "mediaSource": "Voor het aanvragen van {{kind}}",
            "scan": "Scan een document",
            "upload": "Upload een document",
            "text": "Voer tekst in",
            "kind": "{{kind}} in behandeling...",
            "responseLang": " in ",
            "question": "Voer uw vraag in",
            "send": "Verzenden",
            "chooseSourceType": "Ik zoek",
            "comingSoon": "Binnenkort beschikbaar",
            "homeTitle": "Vereenvoudig uw leven met de kracht van ChatGPT 4",
            "homeText": "Revolutioneer uw ervaring met onze innovatieve app met ChatGPT 4 en andere AI! <br/>" +
                "Scan, upload en verken documenten in een oogwenk.<br/>" +
                "Chat in tekst of spraak, maak dialogen en leer nieuwe talen met gemak.<br/>" +
                "Doe nu mee! 🚀<br/>",
            "generateConversation": "Gesprek genereren",
            "voiceConversation": "ChatGPT 4-voice",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Specifieke vraag",
            "conversations": "Mijn gesprekken",
            "uploads": "Mijn uploads/scans",
            "clear": "Wissen",
            "errorInternetConnection": "Fout: Geen internetverbinding, probeer het later opnieuw.",
            "result": "Resultaat",
            "resultDocument": "Antwoorden krijgen op vragen in het document.",
            "understandDocument": "Het begrijpen van de inhoud van het document.",
            "summaryDocument": "Een samenvatting van het document krijgen.",
            "downloadPdf": "Download PDF",
            "unsatisfactoryResultMessage": "Als de resultaten niet bevredigend zijn, klik dan op ",
            "imageUploadSuccess": "Afbeelding succesvol geüpload!",
            "downloadForEdition": "Downloaden<br/>om te bewerken",
            "resendImage": "Afbeelding<br/>opnieuw verzenden",
            "delete": "Verwijderen",
            "retry": "Opnieuw proberen",
            "imageLoading": "Afbeelding laden",
            "howItWork": "Hoe het werkt",
            "retryIndication": "Als uw document oefeningen of academische examens bevat, kan AI deze niet direct verwerken. Om dit op te lossen, hebben we uw document omgezet in afbeeldingen, die u kunt downloaden en bewerken zoals u dat wilt.<br/>" +
                "<br/>" +
                "Elementen die uit het document moeten worden verwijderd, zijn onder andere:<br/><dl>" +
            "<dd>- Schoollogo en naam</dd>" +
                    "<dd>- Documenttitel</dd>" +
                "<dd>- Beoordeling of cijfer voor elke vraag</dd>" +
                "<dd>- Informatie die de oorsprong van het document aangeeft</dd></dl><br/>" +
                "Hoe verder te gaan:" +
                "<ol>" +
                "<li>Downloaden voor bewerking: Hiermee kunt u de afbeelding naar uw apparaat downloaden. Gebruik een beeldbewerkingsapplicatie zoals Paint op Windows.</li>" +
                "<li>Opnieuw verzenden van bewerkte afbeelding: Hiermee kunt u de zojuist gewijzigde afbeelding uploaden.</li>" +
                "<li>Verwijderen: In sommige gevallen moet u de afbeelding mogelijk verwijderen als deze geen oefeningen bevat.</li>" +
                "<li>Nadat de afbeeldingen zijn bewerkt, klik op de knop Opnieuw proberen.</li></ol>",

            "history": "Geschiedenis",
            "originalDocument": "Origineel document",
            "generatedImagesList": "Lijst van gegenereerde afbeeldingen",
            "page": "Pagina",
            "requestDate": "Aanvraagdatum",
            "usedPagesList": "Lijst van gebruikte pagina's",
            "response": "Antwoord",
            "noDownloadsAvailable": "Geen downloads beschikbaar",

            "story": "een verhaal",
            "riddle": "een uitdaging",
            "joke": "een grap",
            "useKeyboard": "Ik typ op het toetsenbord",
            "useMicrophone": "Ik spreek in de microfoon",
            "usedrawing": "Ik scan mijn tekening",
            "tellStory": "Vertel me {{kind}} over",
            "sayAndClick": "Zeg wat je wilt en klik dan hier",
            "takePhotoAndClick": "Maak een foto van je tekening en klik hier",
            "start": "Klik hier om te beginnen",
            "wildAnimals": "Voorbeeld: Wilde dieren",
            "home": "Thuis",
            "myChannel": "Mijn kanaal",
            "subscriptions": "Abonnementen",
            "hideSolution": "Oplossing verbergen",
            "showSolution": "Oplossing tonen",
            "makeVideoStory": "Ik maak mijn magische film",
            "makeAudioStory": "Ik ontwerp mijn eigen geluidsverhaal",
            "startFunChallenge": "Ik los mysterieuze raadsels op",
            "craftMyJokes": "Ik creëer mijn eigen wereld van grappen",
            "watchStories": "Ik duik in mijn films",
            "listenToStories": "Ik ontdek mijn wonderbaarlijke verhalen",
            "funChallenges": "Ik ga mijn fantastische uitdagingen aan",
            "exploreMyJokes": "Ik verken mijn universum van grappen",
            "storyInPreparation": "Verhaal in voorbereiding... Waarom niet",
            "listenAnotherStory": "luister naar een ander verhaal",
            "watchAnotherStory": "bekijk een ander verhaal",
            "playARiddle": "speel een raadsel",
            "giggleWithAnotherJoke": "Giechel met een andere grap",
            "pending": "in afwachting",
            "myAccount": "Mijn account",
            "signOut": "Uitloggen",
            "signInWithGoogle": "Inloggen",
            "featureNotYetAvailable": "Functie nog niet beschikbaar",
            "title": "Titel:",
            "yearsOld": "jaar oud",
            "im": "Ik ben",
            "show": "Tonen",
            "hide": "Verbergen",
            "showStory": "Toon verhaaltekst",
            "hideStory": "Verberg verhaaltekst",
            "surpriseMe": "Verras me",
            "chooseForMe":"",
            "english":"Engels",
            "spanish":"Spaans",
            "arabic":"Arabisch",
            "french":"Frans",
            "german":"Duits",
            "chinese":"Chinees",
            "japanese":"Japans",
            "hindi":"Hindi",
            "turkish":"Turks",
            "korean":"Koreaans",
            "italian":"Italiaans",
            "dutch":"Nederlands",
            "russian":"Russisch",
            "indonesian":"Indonesisch",
            "alphabet": "Alfabet",
            "numbers": "Nummers",
            "learning": "Leren",
            "languages": "Talen",
            "selectLanguage": "Selecteer een taal",
            "selectLearningType": "Selecteer een leertype",
            "words": "Woorden",
            "selectType": "Selecteer een type",
            "animals": "Dieren",
            "colors": "Kleuren",
            "fruits": "Fruit",
            "vegetables": "Groenten",
            "foods": "Voedsel",
            "verbs": "Werkwoorden",
            "houses": "Huis",
            "schools": "School",
            "supplies": "Schoolbenodigdheden",
            "natures": "Natuur",
            "places": "Plaatsen en Vervoer",
            "occupations": "Beroepen",
            "iWantToLearn" : "Ik wil leren",
        }
    },
    tr: {
        translation: {
            "playAll": "Hepsini oynat",
            "conversationIn": "Konuşma",
            "between": "arasında",
            "and": "ve",
            "subject": "Konu",
            "conversation": "Konuşma",
            "copy": "KOPYALA",
            "assistance": "Yardım",
            "answers": "Cevaplar",
            "explanations": "Analiz Et ve Açıkla",
            "summary": "Özet",
            "translation": "Çeviri",
            "other": "Diğer talep",
            "other_example": "Örnek: Bu belgeyi analiz et",
            "audio": "Ses",
            "mediaSource": "{{kind}} talep etmek için",
            "scan": "Belgeyi Tara",
            "upload": "Belgeyi Yükle",
            "text": "Metin Gir",
            "kind": "{{kind}} hazırlanıyor...",
            "responseLang": " içinde ",
            "question": "Sorunuzu Girin",
            "send": "Gönder",
            "chooseSourceType": "Ara",
            "comingSoon": "Yakında",
            "homeTitle": "ChatGPT 4 Gücüyle Hayatınızı Kolaylaştırın",
            "homeText": "ChatGPT 4 ve diğer yapay zeka ile deneyiminizi devrimleştirin! <br/>" +
                "Belgeleri tarayın, yükleyin ve bir göz kırpmasıyla keşfedin.<br/>" +
                "Metin veya sesle sohbet edin, diyaloglar oluşturun ve yeni dilleri kolayca öğrenin.<br/>" +
                "Şimdi katılın! 🚀<br/>",
            "generateConversation": "Sohbet Oluştur",
            "voiceConversation": "Sesli ChatGPT 4",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Belirli Soru",
            "conversations": "Sohbetlerim",
            "uploads": "Yüklemelerim/Taramalarım",
            "clear": "Temizle",
            "errorInternetConnection": "Hata: İnternet bağlantısı yok, lütfen daha sonra tekrar deneyin.",
            "result": "Sonuç",
            "resultDocument": "Belgenin sorularına cevap alın.",
            "understandDocument": "Belgenin içeriğini anlamak.",
            "summaryDocument": "Belgenin özetini almak.",
            "downloadPdf": "PDF İndir",
            "unsatisfactoryResultMessage": "Sonuçlar tatmin edici değilse, tıklayın ",
            "imageUploadSuccess": "Resim başarıyla yüklendi!",
            "downloadForEdition": "İndir<br/>düzenleme için",
            "resendImage": "Resmi<br/>yeniden gönder",
            "delete": "Sil",
            "retry": "Tekrar dene",
            "imageLoading": "Resim yükleniyor",
            "howItWork": "Nasıl çalışır",
            "retryIndication": "Belgenizin egzersizler veya akademik sınavlar içermesi durumunda, YZ bunları doğrudan işleyemez. Bunu çözmek için belgenizi resimlere dönüştürdük; bunları indirebilir ve ihtiyacınıza göre düzenleyebilirsiniz.<br/>" +
                "<br/>" +
                "Belgeden kaldırılması gereken unsurlar şunları içerir:<br/><dl>" +
            "<dd>- Okul logosu ve adı</dd>" +
                    "<dd>- Belge başlığı</dd>" +
                "<dd>- Her soru için puanlama veya not</dd>" +
                "<dd>- Belgenin kaynağını belirten herhangi bir bilgi</dd></dl><br/>" +
                "Nasıl devam edilir:" +
                "<ol>" +
                "<li>Düzenleme için İndir: Görüntüyü cihazınıza indirmenizi sağlar. Windows üzerindeki Paint gibi bir görüntü düzenleme uygulamasını kullanın.</li>" +
                "<li>Düzenlenmiş görüntüyü yeniden gönderme: Yeni düzenlediğiniz görüntüyü yüklemenizi sağlar.</li>" +
                "<li>Silme: Bazı durumlarda, görüntü herhangi bir egzersiz içermiyorsa silmeniz gerekebilir.</li>" +
                "<li>Görüntüler düzenlendikten sonra, 'Yeniden Dene' düğmesine tıklayın.</li></ol>",

            "history": "Geçmiş",
            "originalDocument": "Orijinal belge",
            "generatedImagesList": "Oluşturulan görüntüler listesi",
            "page": "Sayfa",
            "requestDate": "Talep Tarihi",
            "usedPagesList": "Kullanılan sayfalar listesi",
            "response": "Cevap",
            "noDownloadsAvailable": "İndirme yok",

            "story": "bir hikaye",
            "riddle": "bir meydan okuma",
            "joke": "bir şaka",
            "useKeyboard": "Klavyede yazıyorum",
            "useMicrophone": "Mikrofonda konuşuyorum",
            "usedrawing": "Çizimimi tarıyorum",
            "tellStory": "Bana {{kind}} hakkında anlat",
            "sayAndClick": "İstediğinizi söyleyin sonra buraya tıklayın",
            "takePhotoAndClick": "Çiziminin fotoğrafını çek ve buraya tıkla",
            "start": "Başlamak için buraya tıklayın",
            "wildAnimals": "Örnek: Yabani hayvanlar",
            "home": "Ana Sayfa",
            "myChannel": "Kanalım",
            "subscriptions": "Abonelikler",
            "hideSolution": "Çözümü gizle",
            "showSolution": "Çözümü göster",
            "makeVideoStory": "Büyülü filmimi oluşturuyorum",
            "makeAudioStory": "Kendi sesli hikayemi tasarlıyorum",
            "startFunChallenge": "Gizemli bulmacaları çözüyorum",
            "craftMyJokes": "Kendi şaka dünyamı yaratırım",
            "watchStories": "Filmlerime dalarım",
            "listenToStories": "Harika hikayelerimi keşfediyorum",
            "funChallenges": "Muhteşem zorluklarımı aşarım",
            "exploreMyJokes": "Ben şaka evrenimi keşfediyorum",
            "storyInPreparation": "Hikaye hazırlanıyor... Neden olmasın",
            "listenAnotherStory": "başka bir hikaye dinle",
            "watchAnotherStory": "başka bir hikaye izle",
            "playARiddle": "bir bilmece oyna",
            "giggleWithAnotherJoke": "Başka bir şaka ile kıkırdama",
            "pending": "beklemede",
            "myAccount": "Hesabım",
            "signOut": "Çıkış Yap",
            "signInWithGoogle": "Giriş yap",
            "featureNotYetAvailable": "Özellik henüz kullanılamıyor",
            "title": "Başlık:",
            "yearsOld": "yaşında",
            "im": "Ben",
            "show": "Göster",
            "hide": "Gizle",
            "showStory": "Hikaye Metnini Göster",
            "hideStory": "Hikaye Metnini Gizle",
            "surpriseMe": "Beni şaşırt",
            "chooseForMe":"",
            "english":"İngilizce",
            "spanish":"İspanyolca",
            "arabic":"Arapça",
            "french":"Fransızca",
            "german":"Almanca",
            "chinese":"Çince",
            "japanese":"Japonca",
            "hindi":"Hintçe",
            "turkish":"Türkçe",
            "korean":"Korece",
            "italian":"İtalyanca",
            "dutch":"Hollandaca",
            "russian":"Rusça",
            "indonesian":"Endonezyaca",
            "alphabet": "Alfabe",
            "numbers": "Sayılar",
            "learning": "Öğrenme",
            "languages": "Diller",
            "selectLanguage": "Bir dil seçin",
            "selectLearningType": "Öğrenme türünü seçin",
            "words": "Kelimeler",
            "selectType": "Bir tür seçin",
            "animals": "Hayvanlar",
            "colors": "Renkler",
            "fruits": "Meyveler",
            "vegetables": "Sebzeler",
            "foods": "Gıdalar",
            "verbs": "Fiiller",
            "houses": "Ev",
            "schools": "Okul",
            "supplies": "Okul malzemeleri",
            "natures": "Doğa",
            "places": "Yerler ve Ulaşım",
            "occupations": "Meslekler",
            "iWantToLearn" : "Öğrenmek istiyorum",
        }
    },
    ru: {
        translation: {
            "playAll": "Воспроизвести все",
            "conversationIn": "Диалог в",
            "between": "между",
            "and": "и",
            "subject": "Тема",
            "conversation": "Диалог",
            "copy": "КОПИРОВАТЬ",
            "assistance": "Помощь",
            "answers": "Ответы",
            "explanations": "Анализ и Объяснение",
            "summary": "Резюме",
            "translation": "Перевод",
            "other": "Другой запрос",
            "other_example": "Пример: Проанализируйте этот документ",
            "audio": "Аудио",
            "mediaSource": "Для запроса {{kind}}",
            "scan": "Сканировать документ",
            "upload": "Загрузить документ",
            "text": "Ввести текст",
            "kind": "{{kind}} в процессе...",
            "responseLang": " на ",
            "question": "Введите свой вопрос",
            "send": "Отправить",
            "chooseSourceType": "Я ищу",
            "comingSoon": "Скоро будет доступно",
            "homeTitle": "Упростите свою жизнь с помощью мощи ChatGPT 4",
            "homeText": "Революционизируйте свой опыт с нашим инновационным приложением и ChatGPT 4! <br/>" +
                "Сканируйте, загружайте и исследуйте документы в мгновение ока.<br/>" +
                "Общайтесь в тексте или голосом, создавайте диалоги и легко учите новые языки.<br/>" +
                "Присоединяйтесь прямо сейчас! 🚀<br/>",
            "generateConversation": "Создать диалог",
            "voiceConversation": "Голосовой ChatGPT 4",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Конкретный вопрос",
            "conversations": "Мои диалоги",
            "uploads": "Мои загрузки/сканы",
            "clear": "Очистить",
            "errorInternetConnection": "Ошибка: отсутствует подключение к Интернету, пожалуйста, попробуйте позже.",
            "result": "Результат",
            "resultDocument": "Получить ответы на вопросы документа.",
            "understandDocument": "Понять содержание документа.",
            "summaryDocument": "Получить резюме документа.",
            "downloadPdf": "Скачать PDF",
            "unsatisfactoryResultMessage": "Если результаты неудовлетворительны, щелкните ",
            "imageUploadSuccess": "Изображение успешно загружено!",
            "downloadForEdition": "Скачать<br/>для редактирования",
            "resendImage": "Повторно<br/>отправить изображение",
            "delete": "Удалить",
            "retry": "Повторить",
            "imageLoading": "Загрузка изображения",
            "howItWork": "Как это работает",
            "retryIndication": "Если ваш документ содержит упражнения или академические экзамены, ИИ не может обрабатывать их напрямую. Для решения этой проблемы мы преобразовали ваш документ в изображения, которые вы можете скачать и редактировать по необходимости.<br/>" +
                "<br/>" +
                "Элементы, которые следует удалить из документа, включают:<br/><dl>" +
            "<dd>- Логотип и название школы</dd>" +
                    "<dd>- Заголовок документа</dd>" +
                "<dd>- Оценка или оценка для каждого вопроса</dd>" +
                "<dd>- Любая информация, указывающая на происхождение документа</dd></dl><br/>" +
                "Как продолжить:" +
                "<ol>" +
                "<li>Скачать для редактирования: Позволяет скачать изображение на ваше устройство. Используйте приложение для редактирования изображений, такое как Paint на Windows.</li>" +
                "<li>Повторно отправить отредактированное изображение: Позволяет загрузить только что измененное изображение.</li>" +
                "<li>Удаление: В некоторых случаях может потребоваться удалить изображение, если в нем нет упражнений.</li>" +
                "<li>После редактирования изображений нажмите кнопку 'Повторить'.</li></ol>",

            "history": "История",
            "originalDocument": "Оригинальный документ",
            "generatedImagesList": "Список созданных изображений",
            "page": "Страница",
            "requestDate": "Дата запроса",
            "usedPagesList": "Список использованных страниц",
            "response": "Ответ",
            "noDownloadsAvailable": "Нет доступных загрузок",

            "story": "история",
            "riddle": "задача",
            "joke": "шутка",
            "useKeyboard": "Я пишу на клавиатуре",
            "useMicrophone": "Я говорю в микрофон",
            "usedrawing": "Я сканирую свой рисунок",
            "tellStory": "Расскажи мне {{kind}} о",
            "sayAndClick": "Скажите, что вы хотите, затем нажмите сюда",
            "takePhotoAndClick": "Сфотографируй свой рисунок и нажми сюда",
            "start": "Нажмите здесь, чтобы начать",
            "wildAnimals": "Пример: Дикие животные",
            "home": "Главная",
            "myChannel": "Мой канал",
            "subscriptions": "Подписки",
            "hideSolution": "Скрыть решение",
            "showSolution": "Показать решение",
            "makeVideoStory": "Я создаю свой волшебный фильм",
            "makeAudioStory": "Я разрабатываю свою собственную аудио-историю",
            "startFunChallenge": "Я решаю таинственные головоломки",
            "craftMyJokes": "Я создаю свой мир шуток",
            "watchStories": "Я погружаюсь в свои фильмы",
            "listenToStories": "Я открываю для себя свои чудесные истории",
            "funChallenges": "Я принимаю свои фантастические вызовы",
            "exploreMyJokes": "Я исследую свою вселенную шуток",
            "storyInPreparation": "История в подготовке... Почему бы и нет",
            "listenAnotherStory": "послушать другую историю",
            "watchAnotherStory": "посмотреть другую историю",
            "playARiddle": "играть в загадку",
            "giggleWithAnotherJoke": "Хихикать с другой шуткой",
            "pending": "в ожидании",
            "myAccount": "Мой аккаунт",
            "signOut": "Выйти",
            "signInWithGoogle": "Войти",
            "featureNotYetAvailable": "Функция пока недоступна",
            "title": "Название:",
            "yearsOld": "лет",
            "im": "Мне",
            "show": "Показать",
            "hide": "Скрыть",
            "showStory": "Показать текст истории",
            "hideStory": "Скрыть текст истории",
            "surpriseMe": "Удиви меня",
            "chooseForMe":"",
            "english":"Английский",
            "spanish":"Испанский",
            "arabic":"Арабский",
            "french":"Французский",
            "german":"Немецкий",
            "chinese":"Китайский",
            "japanese":"Японский",
            "hindi":"Хинди",
            "turkish":"Турецкий",
            "korean":"Корейский",
            "italian":"Итальянский",
            "dutch":"Голландский",
            "russian":"Русский",
            "indonesian":"Индонезийский",
            "alphabet": "Алфавит",
            "numbers": "Числа",
            "learning": "Обучение",
            "languages": "Языки",
            "selectLanguage": "Выберите язык",
            "selectLearningType": "Выберите тип обучения",
            "words": "Cлова",
            "selectType": "Выберите тип",
            "animals": "Животные",
            "colors": "Цвета",
            "fruits": "Фрукты",
            "vegetables": "овощи",
            "foods": "Еда",
            "verbs": "Глаголы",
            "houses": "Дом",
            "schools": "Школа",
            "supplies": "Школьные принадлежности",
            "natures": "Природа",
            "places": "Места и Транспорт",
            "occupations": "Профессии",
            "iWantToLearn" : "Я хочу учиться",
        }
    },
    id: {
        translation: {
            "playAll": "Putar Semua",
            "conversationIn": "Percakapan di",
            "between": "antara",
            "and": "dan",
            "subject": "Subjek",
            "conversation": "Percakapan",
            "copy": "SALIN",
            "assistance": "Bantuan",
            "answers": "Jawaban",
            "explanations": "Analisis dan Jelaskan",
            "summary": "Ringkasan",
            "translation": "Terjemahan",
            "other": "Permintaan lain",
            "other_example": "Contoh: Analisis dokumen ini",
            "audio": "Audio",
            "mediaSource": "Untuk meminta {{kind}}",
            "scan": "Pindai dokumen",
            "upload": "Unggah dokumen",
            "text": "Masukkan teks",
            "kind": "{{kind}} dalam proses...",
            "responseLang": " dalam ",
            "question": "Masukkan pertanyaan Anda",
            "send": "Kirim",
            "chooseSourceType": "Saya mencari",
            "comingSoon": "Segera hadir",
            "homeTitle": "Mudahkan Hidup Anda dengan Kekuatan ChatGPT 4",
            "homeText": "Revolutionkan pengalaman Anda dengan aplikasi inovatif kami dan ChatGPT 4! <br/>" +
                "Pindai, unggah, dan jelajahi dokumen dalam sekejap mata.<br/>" +
                "Bercakap dalam teks atau suara, ciptakan dialog, dan pelajari bahasa baru dengan mudah.<br/>" +
                "Bergabunglah sekarang! 🚀<br/>",
            "generateConversation": "Buat Percakapan",
            "voiceConversation": "ChatGPT 4 Suara",
            "textConversation": "ChatGPT 4",
            "specificQuestion": "Pertanyaan Tertentu",
            "conversations": "Percakapan Saya",
            "uploads": "Unggahan/Scan Saya",
            "clear": "Bersihkan",
            "errorInternetConnection": "Kesalahan: Tidak ada koneksi internet, harap coba lagi nanti.",
            "result": "Hasil",
            "resultDocument": "Dapatkan jawaban untuk pertanyaan dokumen.",
            "understandDocument": "Pahami konten dokumen.",
            "summaryDocument": "Dapatkan ringkasan dokumen.",
            "downloadPdf": "Unduh PDF",
            "unsatisfactoryResultMessage": "Jika hasil tidak memuaskan, klik pada ",
            "imageUploadSuccess": "Gambar berhasil diunggah!",
            "downloadForEdition": "Unduh<br/>untuk diedit",
            "resendImage": "Kirim ulang<br/>gambar",
            "delete": "Hapus",
            "retry": "Coba lagi",
            "imageLoading": "Sedang memuat gambar",
            "howItWork": "Bagaimana cara kerjanya",
            "retryIndication": "Jika dokumen Anda berisi latihan atau ujian akademis, KI tidak dapat memprosesnya secara langsung. Untuk mengatasi hal ini, kami mengonversi dokumen Anda menjadi gambar, yang dapat Anda unduh dan edit sesuai kebutuhan.<br/>" +
                "<br/>" +
                "Elemen-elemen yang perlu dihapus dari dokumen meliputi:<br/><dl>" +
                "<dd>- Logo dan nama sekolah</dd>" +
                "<dd>- Judul dokumen</dd>" +
                "<dd>- Penilaian atau nilai untuk setiap pertanyaan</dd>" +
                "<dd>- Informasi apa pun yang menunjukkan asal dokumen</dd></dl><br/>" +
                "Cara melanjutkan:" +
                "<ol>" +
                "<li>Unduh untuk diedit: Memungkinkan Anda mengunduh gambar ke perangkat Anda. Gunakan aplikasi pengeditan gambar, seperti Paint di Windows.</li>" +
                "<li>Kirim ulang gambar yang sudah diedit: Memungkinkan Anda mengunggah gambar yang baru saja diubah.</li>" +
                "<li>Penghapusan: Dalam beberapa kasus, Anda mungkin perlu menghapus gambar jika tidak berisi latihan.</li>" +
                "<li>Setelah gambar diedit, klik tombol Coba Lagi.</li></ol>",

            "history": "Sejarah",
            "originalDocument": "Dokumen asli",
            "generatedImagesList": "Daftar gambar yang dihasilkan",
            "page": "Halaman",
            "requestDate": "Tanggal Permintaan",
            "usedPagesList": "Daftar halaman yang digunakan",
            "response": "Respon",
            "noDownloadsAvailable": "Tidak ada unduhan yang tersedia",

            "story": "sebuah cerita",
            "riddle": "tantangan",
            "joke": "lelucon",
            "useKeyboard": "Saya mengetik di keyboard",
            "useMicrophone": "Saya berbicara di mikrofon",
            "usedrawing": "Saya memindai gambar saya",
            "tellStory": "Ceritakan {{kind}} tentang",
            "sayAndClick": "Katakan apa yang Anda inginkan lalu klik di sini",
            "takePhotoAndClick": "Ambil foto gambarmu dan klik di sini",
            "start": "Klik di sini untuk memulai",
            "wildAnimals": "Contoh: Hewan liar",
            "home": "Beranda",
            "myChannel": "Saluran saya",
            "subscriptions": "Langganan",
            "hideSolution": "Sembunyikan solusi",
            "showSolution": "Tampilkan solusi",
            "makeVideoStory": "Saya membuat film ajaib saya",
            "makeAudioStory": "Saya merancang cerita audio saya sayAndClickiri",
            "startFunChallenge": "Saya menyelesaikan teka-teki misterius",
            "craftMyJokes": "Saya menciptakan dunia lelucon saya sendiri",
            "watchStories": "Saya menyelam ke dalam film saya",
            "listenToStories": "Saya menemukan cerita ajaib saya",
            "funChallenges": "Saya menghadapi tantangan fantastis saya",
            "exploreMyJokes": "Saya menjelajahi alam semesta lelucon saya",
            "storyInPreparation": "Cerita dalam persiapan... Mengapa tidak",
            "listenAnotherStory": "dengarkan cerita lain",
            "watchAnotherStory": "tonton cerita lain",
            "playARiddle": "mainkan teka-teki",
            "giggleWithAnotherJoke": "Tertawa dengan lelucon lainnya",
            "pending": "tunda",
            "myAccount": "Akun Saya",
            "signOut": "Keluar",
            "signInWithGoogle": "Masuk",
            "featureNotYetAvailable": "Fitur belum tersedia",
            "title": "Judul:",
            "yearsOld": "tahun",
            "im": "Saya berumur",
            "show": "Tampilkan",
            "hide": "Sembunyikan",
            "showStory": "Tampilkan Teks Cerita",
            "hideStory": "Sembunyikan Teks Cerita",
            "surpriseMe": "Kejutkan saya",
            "chooseForMe":"",
            "english":"Inggris",
            "spanish":"Spanyol",
            "arabic":"Arab",
            "french":"Perancis",
            "german":"Jerman",
            "chinese":"Cina",
            "japanese":"Jepang",
            "hindi":"Hindi",
            "turkish":"Turki",
            "korean":"Korea",
            "italian":"Italia",
            "dutch":"Belanda",
            "russian":"Rusia",
            "indonesian":"Indonesia",
            "alphabet": "Abjad",
            "numbers": "Nomor",
            "learning": "Pembelajaran",
            "languages": "Bahasa",
            "selectLanguage": "Pilih bahasa",
            "selectLearningType": "Pilih jenis belajar",
            "words": "Kata-kata",
            "selectType": "Pilih tipe",
            "animals": "Hewan",
            "colors": "Warna",
            "fruits": "Buah",
            "vegetables": "Sayuran",
            "foods": "Makanan",
            "verbs": "Kata Kerja",
            "houses": "Rumah",
            "schools": "Sekolah",
            "supplies": "Perlengkapan sekolah",
            "natures": "Alam",
            "places": "Tempat & Transportasi",
            "occupations": "Pekerjaan",
            "iWantToLearn" : "Saya ingin belajar",
        }
    }
};

let defaultLang = localStorage.getItem('language');

if (!defaultLang) {
    defaultLang = navigator.language.slice(0, 2) || 'en';
    localStorage.setItem('language', defaultLang);
}

i18n
    .use(Backend)
    .use(initReactI18next) // passe l'instance de i18next à react-i18next.
    .init({
        resources,
        lng: defaultLang,
        fallbackLng: 'en',
        debug: true,
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        interpolation: {
            escapeValue: false // react déjà sécurisé.
        },
        ns: ['translation'],
        defaultNS: 'translation',
    });

export default i18n;
