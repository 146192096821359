import styled from "@emotion/styled";
import ReactPlayer from "react-player";

export const CardContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Card = styled.div`
    background-color: #ffffff;
    border-radius: 15px;
    padding: 5px;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important; // Important is used here because ReactPlayer sets width and height directly
    height: 100% !important;
`;

export const FooterItem = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
`;

export const FooterLabel = styled.span`
    color: #000000;
    padding-left: 10px;
`;

export const Button = styled.button`
    align-self: center;
    font-size: 12px;
    margin-bottom: 10px;
    padding: 7px 7px;
    background: #dde5f9;  // purple background
    color: black;  // white text
    border-radius: 7px;  // rounded border
    font-family: Space Grotesk,sans-serif;
    cursor: pointer;  // change cursor on hover
    transition: background-color 0.3s;  // smooth color transition
    margin-right: 10px;
    border: 1px solid #4a148c;
    // change background color on hover
    &:hover {
        background-color: #dde5f9;  // darker purple
    }

    &:last-child {
        margin-bottom: 0;  // remove margin from the last button
    }

    @media (min-width: 600px) {
        margin-right: 10px;
        margin-bottom: 0;
    }
    
    &:disabled {
        background-color: #dedada;  // grey background when disabled
        border-color: #dedada;
        cursor: not-allowed;  // change cursor when disabled
    }
`;
