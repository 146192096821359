import React, {useEffect, useState} from 'react';
import AudioPlayer from './AudioPlayer';
import { ReactComponent as FastForwardIcon } from './icons/fast_forward_24px.svg';
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";

const AllButton = styled.button`
  font-size: 14px;
  padding: 5px 10px;
  color: black;
  background-color: white;
  border: none;
  border-radius: 10px;
  font-family: Space Grotesk,sans-serif;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
  border: 1px solid #4a148c;
  margin-bottom: 5px;

  &:hover {
    background-color: #dde5f9;
  }

  @media (max-width: 600px) {
  }

  &:disabled {
    background-color: #757575;  // grey background when disabled
    cursor: not-allowed;  // change cursor when disabled
  }
`;

interface Dialogue {
  audio_url: string;
  sentence: string;
  phrase:string;
}

interface DialogueListProps {
  dialogue: Dialogue[];
  lang: string | null;
  language: string | null;
}

const DialogueListContainer: React.FC<DialogueListProps> = ({ dialogue, lang, language}) => {
  let isRtlTo = lang === 'ar';
  const [current, setCurrent] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [playingAll, setPlayingAll] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setCurrent(current);
    setPlaying(playing);
  }, [current]);

  const playAudioFromIndex = (index: number) => {
    setCurrent(index);
    setPlaying(true);
    setPlayingAll(false);
  }

  const handlePlayAll = () => {
    setCurrent(0);
    setPlaying(true);
    setPlayingAll(true);
  };

  const handleEnd = () => {
    if (current < dialogue.length - 1 && playingAll === true) {
      setCurrent(current + 1);
    } else {
      setPlaying(false);
    }
  };

  return (
    <div style={{direction: isRtlTo ? 'rtl' : 'ltr'}}>
      <div style={{ display: 'flex', justifyContent: 'center', marginRight: '20px' }}>
      <AllButton onClick={handlePlayAll}>{t('playAll')}</AllButton></div>
      {dialogue.map((d, index) => (
        <div key={index}>
          <AudioPlayer
            url={d.audio_url}
            sentence={d.sentence}
            phrase={d.phrase}
            isPlaying={playing && current === index}
            onEnd={handleEnd}
            playAudioFromIndex={() => playAudioFromIndex(index)}
            index={index}
            language={language}
            lang={lang}
          />
        </div>
      ))}
    </div>
  );
};

export default DialogueListContainer;
