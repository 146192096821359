import React, {useContext, useEffect, useState} from "react";
import jwtDecode from 'jwt-decode';
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";
import {ShakeButtonContext} from "./ShakeButtonContext";
import {Card, CardContainer} from './CardStyles';
import useWebSocket from "react-use-websocket";
import i18n from "./i18n";

import Prism from 'prismjs';
import 'prismjs/themes/prism-solarizedlight.css';
import DialogueListContainer from "./DialogueListContainer";
import {CircularProgress} from "@mui/material";
import {useList} from "./store/ListContext";
import { ReactComponent as SendIconSVG } from './icons/send_fill1_24px.svg';
import { ReactComponent as LanguageIcon } from './icons/language_fill1_20px.svg';
import {useToken} from "./store/TokenContext";



const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrer verticalement */
    width: 70%;
    flex-wrap: wrap;
    padding-bottom: 90px;  // Ajuster au besoin
    padding-top: 20px;
    margin: 0 auto; /* Ajout pour centrer horizontalement */
    @media only screen and (max-width: 800px) {
        align-items: initial; /* Réinitialiser l'alignement vertical pour les écrans de taille mobile */
        justify-content: center; /* Centrer horizontalement pour les écrans de taille mobile */
        width: 100%;
    }
`;

const ContainerL1 = styled.div`
    position: absolute;
    top: 4px;
    right: 0px;
    display: flex;
    flex-direction: row;  // Change this from column to row
    flex-wrap: wrap;
    align-items: baseline;
    width: 30%;
    @media only screen and (max-width: 800px) {
        width: 60%;
    }
`;

const FooterContainer = styled.div<FooterContainerProps>`
    background-color: #f2efff;
    position: fixed;
    bottom: 0;
    left: 50%; /* Déplace la gauche du bloc au milieu de son parent */
    transform: translateX(-50%); /* Déplace le bloc horizontalement de moitié de sa largeur vers la gauche */
    width: 80%; /* Largeur du FooterContainer */
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 110px; /* Hauteur du FooterContainer */
    z-index: 1; /* Assure que le FooterContainer est au-dessus du reste du contenu */
    @media only screen and (max-width: 800px) {
        width: 100%;
    }
`;


interface SendIconProps {
    isRtl: boolean;
    onClick: () => Promise<void>;
    // Ajoutez d'autres props personnalisées si nécessaire
}

// Créez un composant stylisé avec les types de props étendus
const StyledSendIcon = styled(SendIconSVG)<SendIconProps>`
    position: absolute;
    right: 10px;
    bottom: 10px;
    fill: #6149c1;
    cursor: pointer;
`;



const StoryPrompt = styled.span`
    font-size: 12px;
    padding-bottom: 5px;
    font-family: Space Grotesk,sans-serif;
    margin-right: 10px;
`;

const StoryTextArea = styled.textarea`
    position: absolute;
    bottom: 10px;
    flex-grow: 1;
    padding: 7px;
    border-radius: 10px;
    width: 80%;
    height: 45px;
    border: #f2efff;

    @media (max-width: 800px) {
        width: 95%;
    }
`;

const Select = styled.select`
    right: 4px;  
    font-size: 14px;
    padding: 4px;
    border-radius: 10px;  // rounded border
    border: 1px solid #000000; // purple border
    height: 40px;
    @media (max-width: 800px) {
    }
`;

interface FooterContainerProps {
    disabled?: boolean; // Propriété disabled optionnelle de type boolean
}

const LANGUAGE_OPTIONS = [
    'english',
    'spanish',
    'arabic',
    'french',
    'german',
    'chinese',
    'japanese',
    'hindi',
    'turkish',
    'korean',
    'italian',
    'dutch',
    'russian',
    'indonesian',
] as const;
type LanguageOption = typeof LANGUAGE_OPTIONS[number];

const languageCodeToName: Record<string, LanguageOption> = {
    'en': 'english',
    'es': 'spanish',
    'ar': 'arabic',
    'fr': 'french',
    'de': 'german',
    'zh': 'chinese',
    'ja': 'japanese',
    'hi': 'hindi',
    'tr': 'turkish',
    'ko': 'korean',
    'it': 'italian',
    'nl': 'dutch',
    'ru': 'russian',
    'id': 'indonesian',
};
const languageNameToCode: Record<LanguageOption, string> = {
    'english': 'en',
    'spanish': 'es',
    'arabic': 'ar',
    'french': 'fr',
    'german': 'de',
    'chinese': 'zh',
    'japanese': 'ja',
    'hindi': 'hi',
    'turkish': 'tr',
    'korean': 'ko',
    'italian': 'it',
    'dutch': 'nl',
    'russian': 'ru',
    'indonesian': 'id',
};

type JWTDeCode  = {
    sub: string,
    email: string,
    iat: number,
    exp: number
}

type MediaResult = {
    audio_url: string;
    sentence: string;
    phrase:string;
};
const Home = () => {
    const [result, setResult] = useState<MediaResult | null>(null);
    const [dialogue, setDialogue] = useState<(MediaResult)[]>([]);
    const [text, setText] = useState<string>("");
    const { shakeButton } = useContext(ShakeButtonContext);

    const [loadingResult, setLoadingResult] = useState(false);
    const [disabledBloc, setDisabledBloc] = useState<boolean>(true);

    const { t } = useTranslation();

    const { token } = useToken();

    const currentLanguage = i18n.language;
    const isRtl = currentLanguage === 'ar';
    const initialLanguage = 'en';
    const [language, setLanguage] = useState<LanguageOption>(languageCodeToName[initialLanguage]);
    const [languageCode, setLanguageCode] = useState<string>(initialLanguage);
    const { setList } = useList();

    const {
        sendMessage,
        lastMessage,
    } = useWebSocket(`wss://wss.assist.studio/?token=${token}`, {
        onOpen: () => {
            console.log('opened');
        },
        shouldReconnect: (closeEvent) => true,
        onClose: event => {
            setLoadingResult(false);
        },
        onError: (error) => {
            console.error('WebSocket error occurred:', error);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    });

    useEffect(() => {
        Prism.highlightAll();
        console.log('fff');
    }, []);

    useEffect(() => {
        setDisabledBloc(token ? false : true);
    }, [token]);

    useEffect(() => {
        if (result) {
            setResult(result);
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [result]);

    useEffect(() => {
        if (lastMessage) {
            const messageData = lastMessage.data;
            try {
                const eventData = JSON.parse(messageData);

                if (!eventData.message && !eventData.phrases) {
                    setResult(prevResult => ({
                        ...prevResult,
                        ...eventData
                    }));
                    if(result) {
                        setDialogue(prevDialogue => [...prevDialogue, result]);
                    }
                    setLoadingResult(false);
                }

                if (eventData.phrases) {
                    setList(eventData.phrases);
                }

                if (eventData.message === "Success") {
                    setLoadingResult(false);
                }
                if (eventData.message === "creditExhaustedError" || eventData.message === "Internal server error") {
                    console.log('creditExhaustedError');
                    setLoadingResult(false);
                }
            } catch (error) {
                console.error('Error parsing event data:', error);
                setLoadingResult(false);
            }
        }
    }, [lastMessage]);

    const submitText = async () => {
        setResult(null);
        setDialogue([]);
        setList([]);

        if (token === null) {
            console.error("No token found in localStorage.");
            shakeButton();
            return;
        }

        const decodedToken : JWTDeCode = jwtDecode(token);
        const user_id = decodedToken.sub;

        const payload = {
            subject: text,
            language: languageCode,
            my_language: languageCodeToName[currentLanguage],
            action: 'dialogue',
            user_id: user_id,
        };

        sendMessage(JSON.stringify(payload));

        setLoadingResult(true);
    };

    const handleLanguageChange = (selectedLanguage: LanguageOption) => {
        setLanguage(selectedLanguage);
        const lang = languageNameToCode[selectedLanguage];
        setLanguageCode(lang);
        console.log(languageCode);
    };

    return (
        <div>
            <Container style={{direction: isRtl ? 'rtl' : 'ltr'}}>`
                {/*<span hidden={!errorMessage} style={{color: 'red', marginTop: '50px'}}>{t('errorInternetConnection')}</span>*/}

                {loadingResult && (
                    <CircularProgress disableShrink={true}/>
                )}
                {dialogue.length > 0 && (
                    <CardContainer>
                        <Card>
                            <DialogueListContainer dialogue={dialogue} lang={languageCode} language={language}/>
                        </Card>
                    </CardContainer>
                )}

            </Container>
            <FooterContainer style={{direction: isRtl ? 'rtl' : 'ltr'}}>
                <ContainerL1>
                    <StoryPrompt>{t('conversationIn')}</StoryPrompt>
                    <Select value={language} onChange={(e) => handleLanguageChange(e.target.value as LanguageOption)}>
                        <option value=""><LanguageIcon/></option>
                        {LANGUAGE_OPTIONS.map((lang) => (
                            <option key={lang} value={lang}>
                                {t(lang)}
                            </option>
                        ))}
                    </Select>
                </ContainerL1>
                <StoryTextArea value={text} onChange={e => setText(e.target.value)}>
                </StoryTextArea>
                <StyledSendIcon onClick={submitText} isRtl={isRtl} >{t('send')}</StyledSendIcon>

            </FooterContainer>
        </div>
    );
};

export default Home;
